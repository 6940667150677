import React from 'react';
import { Modal, message } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default ({ visible, onCancel, onOk, okButtonProps }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
        // Block native form submission.
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        console.log({ cardElement })
        // Use your card Element with other Stripe.js APIs
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            message.error(error.message);
        } else {
            console.log('[PaymentMethod]', token);
            onOk(token.id);
        }
    };

    return (
        <Modal
            title='Add a credit card to pay brands (non USD payouts)'
            visible={visible}
            onCancel={() => onCancel()}
            onOk={() => handleSubmit()}
            okText='Add Card'
            okButtonProps={okButtonProps}
        >
            <CardElement />
        </Modal>
    );
};