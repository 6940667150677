export const LOGIN_LOADING = 'LOGIN_LOADING';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const GET_SHOPIFY_COLLECTION = 'GET_SHOPIFY_COLLECTION';
export const GET_SHOPIFY_COLLECTION_LOADING = 'GET_SHOPIFY_COLLECTION_LOADING';
export const GET_PARTNERSHIPS_LOADING = 'GET_PARTNERSHIPS_LOADING';
export const GET_PARTNERSHIPS = 'GET_PARTNERSHIPS';
export const ACCEPT_PARTNERSHIP_LOADING = 'ACCEPT_PARTNERSHIP_LOADING';
export const UPDATE_PARTNERSHIP_LOADING = 'UPDATE_PARTNERSHIP_LOADING';
export const CANCEL_PARTNERSHIP_LOADING = 'CANCEL_PARTNERSHIP_LOADING';
export const CREATE_PARTNERSHIP_LOADING = 'CREATE_PARTNERSHIP_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_PARTNERSHIP_PRODUCTS_LOADING = 'GET_PARTNERSHIP_PRODUCTS_LOADING'
export const GET_PARTNERSHIP_PRODUCTS = 'GET_PARTNERSHIP_PRODUCTS'
export const CREATE_PARTNERSHIP_PRODUCT_LOADING = 'CREATE_PARTNERSHIP_PRODUCT_LOADING';
export const GET_PAYOUTS_LOADING = 'GET_PAYOUTS_LOADING';
export const GET_PAYOUTS = 'GET_PAYOUTS';
export const GET_ORDERS_LOADING = 'GET_ORDERS_LOADING';
export const GET_ORDERS = 'GET_ORDERS';
export const DELETE_PARTNERSHIP_PRODUCT_LOADING = 'DELETE_PARTNERSHIP_PRODUCT_LOADING';
export const CHECKING_SCOPES_LOADING = 'CHECKING_SCOPES_LOADING';
export const CHECKING_SCOPES = 'CHECKING_SCOPES';
export const GET_PARTNERSHIP_REPORT = 'GET_PARTNERSHIP_REPORT';
export const GET_PARTNERSHIP_REPORT_LOADING = 'GET_PARTNERSHIP_REPORT_LOADING';
export const GET_SHOPIFY_SHIPPING_ZONES = 'GET_SHOPIFY_SHIPPING_ZONES';
export const GET_SHOPIFY_SHIPPING_ZONES_LOADING = 'GET_SHOPIFY_SHIPPING_ZONES_LOADING';
export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS = 'GET_USERS';
export const GET_DISCOVER_USER_LOADING = 'GET_DISCOVER_USER_LOADING';
export const GET_DISCOVER_USER = 'GET_DISCOVER_USER';
export const GET_ACCOUNT_LINK_LOADING = 'GET_ACCOUNT_LINK_LOADING';
export const ADD_CARD_LOADING = 'ADD_CARD_LOADING';
export const ADD_STRIPE_ACCOUNT_WITH_PLAID_LOADING = 'ADD_STRIPE_ACCOUNT_WITH_PLAID_LOADING';
export const GET_PLAID_TOKEN_LINK_LOADING = 'GET_PLAID_TOKEN_LINK_LOADING';
export const GET_PLAID_TOKEN_LINK = 'GET_PLAID_TOKEN_LINK';
export const VERIFY_BANK_LOADING = 'VERIFY_BANK_LOADING';
export const GET_PARTNERSHIP_LOADING = 'GET_PARTNERSHIP_LOADING';
export const GET_PARTNERSHIP = 'GET_PARTNERSHIP';
export const UPDATE_PARTNERSHIP = 'UPDATE_PARTNERSHIP';
export const SYNC_PARTNERSHIP_PRODUCTS_LOADING = 'SYNC_PARTNERSHIP_PRODUCTS_LOADING';
export const ADD_SPREEDLY_CARD_LOADING = 'ADD_SPREEDLY_CARD_LOADING';
export const CREATE_LINK_LOADING = 'CREATE_LINK_LOADING';
export const UPDATE_LINK_LOADING = 'UPDATE_LINK_LOADING';
export const GET_LINK_LOADING = 'GET_LINK_LOADING';
export const GET_LINK = 'GET_LINK';
export const GET_LINKS_LOADING = 'GET_LINKS_LOADING';
export const GET_LINKS = 'GET_LINKS';
export const GET_USER_SHOP_URL_LOADING = 'GET_USER_SHOP_URL_LOADING';
export const GET_USER_SHOP_URL = 'GET_USER_SHOP_URL';
export const CREATE_PARTNERSHIP_WITH_LINK_LOADING = 'CREATE_PARTNERSHIP_WITH_LINK_LOADING';