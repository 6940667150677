import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '../constants/Colors';

const Container = styled.div`
    width: 100%;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 20px;
`
const Bar = styled.div`
    display: flex;
    width: 20%;
    justify-content: space-between;
    background: ${props => props.percent >= props.barPercent ? PRIMARY : '#D8D8D8'};
    height: 15px;
`
const Percent = styled.p`
    margin-bottom: 0px;
    margin-left: 10px;
`
export default ({ percent }) => (
    <Container>
        <Bar percent={percent} barPercent={25} />
        <Bar percent={percent} barPercent={50} />
        <Bar percent={percent} barPercent={75} />
        <Bar percent={percent} barPercent={100} />
        <Percent>{percent}%</Percent>
    </Container>
)