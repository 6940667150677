import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '../constants/Colors';

const Container = styled.div`
    width: 25%;
    background: ${PRIMARY};
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 30%;
    @media (max-width: 768px) {
        display: none;
    }
`

const Title = styled.h2`
    color: white;
    font-size: 18px;
`;
const Subtitle = styled.p`
    color: white;
`;

export default (props) => (
    <Container>
        <Title>
            {props.title}
        </Title>
        <Subtitle>
            {props.subtitle}
        </Subtitle>
    </Container>
)