import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import firebase from 'firebase';
import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import connectScreen from '../../redux/connectScreen';
import Input from '../../components/Input';
import Button from '../../components/Button';

const AuthContainer = styled.div`
    margin-left: 250px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7F8FC;
    min-height: 100%;
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`

const NonAuthContainer = styled.div`
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #F7F8FC;
`

const Logo = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: contain;
    background: #CCCCCC;
`

const Company = styled.h2`
    margin-top: 20px;
`
const Card = styled.div`    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #DFE3E7;
    padding: 100px;
    border-radius: 50px;
    background: white;
    @media (max-width: 768px) {
        padding: 40px;
    }

`

const Desc = styled.p`
    width: 50%;
    text-align: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const AcceptInput = styled(Input)`
    width: 75%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const AcceptButton = styled(Button)`
    width: 75%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

class Invite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: '',
            email: '',
            password: '',
            loading: false
        }
    }
    componentDidMount() {
        const { code } = this.props.match.params;
        this.props.getLink(code);
    }

    handleGetShop() {
        const { shop } = this.state;
        const { code } = this.props.match.params;
        const data = {
            shop,
            code
        }
        this.props.getUserWithShopURL(data)
    }

    async handleAccept() {
        //Create partnership with code
        //Redirect to /partners page
        const { code } = this.props.match.params;
        await this.props.createPartnershipWithLink({ code });
        window.location.replace('/partners');
    }

    handleSignup() {
        //redirect to install url
        const { linkShopURL } = this.props.link;
        window.location.replace(linkShopURL.redirectURL);
    }

    async handleLogin() {
        try {
            const { email, password } = this.state
            await this.setState({ loading: true });
            await firebase.auth().signInWithEmailAndPassword(email, password);
            await this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false })
            message.error(e.message)
        }
    }

    render() {
        const { linkLoading, link, userShopURLLoading, linkShopURL, creatingPartnershipWithLink } = this.props.link;
        const { isLoggedIn,  } = this.props.user;
        const { loading } = this.state;
        if (linkLoading || !link) {
            return <Container><LoadingSpinner /></Container>
        } else {
            if (isLoggedIn) {
                return ( 
                    <AuthContainer>
                        <Card>
                            <Logo src={link.user.logo || ''} />
                            <Company>{link.user.company}</Company>
                            <Desc>
                             {link.user.company} wants {link.inviteType === 'seller' ? `to sell your products on their store. You will receive ${100 - link.fee}% per sale` : `you to sell their products on your store. You will receive ${link.fee}% per sale`}. You can edit this percentage after you accept the partnereship.
                            </Desc> 
                            <AcceptButton
                                style={{ marginTop: 20 }}
                                onClick={() => this.handleAccept()}
                                loading={userShopURLLoading || creatingPartnershipWithLink}
                            >
                                Accept
                        </AcceptButton>
                        </Card>
                    </AuthContainer>
                );
            } else {
                console.log({
                    link
                })
                if (linkShopURL && linkShopURL.redirectURL) {
                    this.handleSignup();
                }
                return (
                    <NonAuthContainer>
                        <Card>
                            <Logo src={link.user.logo || ''} />
                            <Company>{link.user.company}</Company>
                            <Desc>
{link.user.company} wants {link.inviteType === 'seller' ? `to sell your products on their store. You will receive ${100 - link.fee}% per sale` : `you to sell their products on your store. You will receive ${link.fee}% per sale`}. You can edit this percentage after you accept the partnership.
                        </Desc>
                            <AcceptInput
                                placeholder='Enter your Shopify url (yourstore.myshopify.com)'
                                onChange={(e) => this.setState({ shop: e.target.value })}
                            />
                            {
                                linkShopURL && linkShopURL.type === 'login' ?
                                    <>
                                        <AcceptInput
                                            placeholder='Email'
                                            style={{ marginTop: 10 }}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                        <AcceptInput
                                            type='password'
                                            placeholder='Password'
                                            style={{ marginTop: 10 }}
                                            onChange={(e) => this.setState({ password: e.target.value })}
                                        />
                                    </>
                                    : null
                            }
                            <AcceptButton
                                style={{ marginTop: 20 }}
                                onClick={() => linkShopURL && linkShopURL.type === 'login' ? this.handleLogin() : this.handleGetShop()}
                                loading={userShopURLLoading || loading}
                            >
                                {linkShopURL && linkShopURL.type === 'login' ? 'Login' : 'Accept'}
                            </AcceptButton>
                        </Card>
                    </NonAuthContainer>
                )
            }
        }
    }
}

export default connectScreen(withRouter(Invite));
