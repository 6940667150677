import React, { Component } from 'react';
import firebase from 'firebase';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { message, Modal, Alert, Table, Radio } from 'antd';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Submenu from '../components/Submenu';
import Card from '../components/Card';
import Label from '../components/Label';
import Input from '../components/Input';
import Title from '../components/Title';
import Button from '../components/Button';
import Switch from '../components/Switch';
import LogoPicker from '../components/LogoPicker';
import TextArea from '../components/TextArea';
import Subtitle from '../components/Subtitle';
import AddCard from '../components/AddCard';
import AddBank from '../components/AddBank';
import AddManualBank from '../components/AddManualBank';
import VerifyBank from '../components/VerifyBank';
import CopyInput from '../components/CopyInput';
import { INVITE_URL } from '../constants/URL';
//import { SHOPIFY_INSTALL_URL } from '../constants/API';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const SettingSection = styled.div`
    margin-top: 20px;
`
const FormItem = styled.div`
    margin-bottom: 20px;
`

class Settings extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: 'General',
            company: dbUser && dbUser.company ? dbUser.company : '',
            isBrand: dbUser && dbUser.isBrand ? dbUser.isBrand : false,
            isSeller: dbUser && dbUser.isSeller ? dbUser.isSeller : false,
            shop: dbUser && dbUser.shop ? dbUser.shop : null,
            code: dbUser && dbUser.code ? dbUser.code : '',
            payoutEmail: dbUser && dbUser.payoutEmail ? dbUser.payoutEmail : '',
            payoutClientId: dbUser && dbUser.payoutClientId ? dbUser.payoutClientId : '',
            payoutSecretId: dbUser && dbUser.payoutSecretId ? dbUser.payoutSecretId : '',
            logo: dbUser && dbUser.logo ? dbUser.logo : null,
            mediaFilesLink: dbUser && dbUser.mediaFilesLink ? dbUser.mediaFilesLink : '',
            supportEmail: dbUser && dbUser.supportEmail ? dbUser.supportEmail : '',
            brandDescription: dbUser && dbUser.brandDescription ? dbUser.brandDescription : '',
            shopifyModalVisible: false,
            shopifyShopUrl: '',
            shopifyAPIPassword: '',
            cardModalVisible: false,
            bankModalVisible: false,
            manualBankModalVisible: false,
            verifyBankModalVisible: false,
            inviteLinksModal: false,
            newInviteLinkType: '',
            newInviteLinkFee: '',
            newInviteLinkTitle: ''
        }
    }
    componentDidMount() {
        const { shouldRefresh } = queryString.parse(this.props.location.search);
        if (shouldRefresh) {
            window.location.replace('/settings')
        }
        this.initSpreedly()
    }

    initSpreedly() {
        const { user } = this.props;
        const { fbUser } = user;
        const { email } = fbUser;
        window.SpreedlyExpress.init(process.env.REACT_APP_SPREEDLY, {
            company_name: 'Ally Payouts',
            submit_label: 'Add Card',
            sidebar_top_description: 'This card will be charged to send payouts to brands.',
            sidebar_bottom_description: 'You will only be charged after a brands product is sold on your site.'
        }, {
            email
        });

        window.SpreedlyExpress.onPaymentMethod(async (token, paymentMethod) => {

            // Send requisite payment method info to backend
            console.log({
                paymentMethod,
                token
            })
            // await retainSpreedlyCard({ token });
            // await updateUser({
            //     spreedlyPaymentToken: token,
            //     spreedlyVerification: ''
            // })
            // window.location.reload()
        });
    }

    async resetPassword() {
        try {
            const { fbUser } = this.props.user;
            const { email } = fbUser;
            await firebase.auth().sendPasswordResetEmail(email);
            message.success('Reset email sent!')
        } catch (e) {
            message.error(e.message)
        }
    }

    async saveUser() {
        const {
            company,
            isBrand,
            isSeller,
            payoutEmail,
            payoutClientId,
            payoutSecretId,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        } = this.state;
        await this.props.updateUser({
            company,
            isBrand,
            isSeller,
            payoutEmail,
            payoutClientId,
            payoutSecretId,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        })

    }

    async connectShopify() {
        const { shopifyShopUrl, shopifyAPIPassword } = this.state;
        await this.saveUser();
        const isAccessGranted = await this.checkScopes(shopifyShopUrl, shopifyAPIPassword);
        if (isAccessGranted) {
            window.open(`/shopify?shop=${shopifyShopUrl}&accessToken=${shopifyAPIPassword}`, '_self');
        }
    }

    async checkScopes(shop, accessToken) {
        const scopeInstructions = await this.props.checkScopes(shop, accessToken);
        if (scopeInstructions && scopeInstructions.length <= 0) {
            return true;
        } else {
            return false
        }
    }

    async uploadLogo() {
        //uploads to cloudinary and saves url to state
        const {
            REACT_APP_CLOUDINARY,
            REACT_APP_CLOUDINARY_UPLOAD
        } = process.env;
        window.cloudinary.openUploadWidget({
            cloudName: REACT_APP_CLOUDINARY,
            uploadPreset: REACT_APP_CLOUDINARY_UPLOAD
        }, (error, result) => {
            if (result && result.event === "success") {
                console.log(result)
                //Get Image uploaded
                const { secure_url, original_filename } = result.info;
                //Add contracts to state
                this.setState({
                    logo: secure_url,
                    logoFileName: original_filename
                })
            }
        });
    }
    addCard(token, type = 'card') {
        this.saveUser();
        this.props.addCard({ token, type });
    }

    verifyBank(amountOne, amountTwo) {
        const customerBank = this.getExistingSource('bank_account');
        if (customerBank) {
            this.props.verifyBankAccount({ amountOne, amountTwo, bankAccountSource: customerBank.id });
        } else {
            message.error('No bank attached to customer');
        }
    }

    getExistingSource(type = 'card') {
        const { dbUser } = this.props.user;
        const { customerSources } = dbUser;
        console.log({ customerSources })
        if (
            customerSources &&
            customerSources.data &&
            customerSources.data.length > 0
        ) {
            const customerCard = customerSources.data.find(source => source.object === type);
            if (customerCard) {
                return customerCard;
            }
        }
        return null
    }

    async onAddBank() {
        await this.props.getPlaidLinkToken();
        this.setState({ bankModalVisible: true })
    }

    onPlaidSuccess(publicToken, accountId) {
        this.props.addStripeBankWithPlaidAccount({ publicToken, accountId });
    }

    async onManageInviteLinks() {
        await this.props.getLinks();
        this.setState({ inviteLinksModal: true });
    }
    async createLink() {

        const {
            newInviteLinkType,
            newInviteLinkFee
        } = this.state;

        if (
            newInviteLinkType === '' || newInviteLinkFee === ''
        ) {
            message.error('Please enter a fee and select if you are the brand or seller');
        } else {
            await this.props.createLink({ fee: newInviteLinkFee, inviteType: newInviteLinkType })
            await this.props.getLinks();
            this.setState({ createInviteLinkModal: false });
        }
    }

    render() {
        const {
            company,
            isBrand,
            shop,
            code,
            isSeller,
            shopifyModalVisible,
            shopifyShopUrl,
            shopifyAPIPassword,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription,
            cardModalVisible,
            bankModalVisible,
            manualBankModalVisible,
            verifyBankModalVisible,
            inviteLinksModal,
            createInviteLinkModal,
            newInviteLinkType,
            newInviteLinkFee
        } = this.state;
        const { scopeInstructions } = this.props.shopify;
        const {
            updatingUser,
            loadingAddCard,
            plaidLinkToken,
            loadingStripeAccountWithPlaidLoading,
            loadingVerifyBank,
            loadingAccountLink,
            dbUser
        } = this.props.user;
        const { 
            links,
            creatingLink,
            loadingLinks
        } = this.props.link
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        const customerCard = this.getExistingSource('card');
        return (
            <Elements stripe={stripePromise}>

                <Container>
                    <Submenu
                        menuItems={['General', 'Brand', 'Sell']}
                        onSelect={(selected) => this.setState({ selected })}
                        selected={this.state.selected}
                        footer={
                            <Button
                                style={{ width: 100, height: 40, padding: 0 }}
                                loading={updatingUser}
                                onClick={() => this.saveUser()}
                            >
                                Save
                        </Button>
                        }
                    />
                    <Card>
                        {
                            this.state.selected === 'General' ?
                                <div>
                                    <Title style={{ marginBottom: 40 }}>General Settings</Title>
                                    <SettingSection>
                                        <Label>Your invite code</Label>
                                        <Title>{code}</Title>
                                        <Button
                                            onClick={() => this.onManageInviteLinks()}
                                            style={{ background: '#989898', width: isMobile ? '100%' : '30%' }}
                                        >
                                            Manage Invite Links
                                        </Button>
                                    </SettingSection>
                                    <SettingSection>
                                        <Label>Company</Label>
                                        <Input
                                            placeholder='Company'
                                            type='company'
                                            value={company}
                                            onChange={e => this.setState({ company: e.target.value })}
                                        />
                                    </SettingSection>
                                    <SettingSection>
                                        <Label>Change Password</Label>
                                        <Button
                                            onClick={() => this.resetPassword()}
                                            style={{ background: '#989898', width: isMobile ? '100%' : '30%' }}
                                        >
                                            Send Password Reset
                                    </Button>
                                    </SettingSection>
                                    <SettingSection>
                                        <Label>Logout</Label>
                                        <Button
                                            onClick={() => firebase.auth().signOut()}
                                            style={{ background: '#989898', width: isMobile ? '100%' : '30%' }}
                                        >
                                            Logout
                                    </Button>
                                    </SettingSection>

                                </div>
                                : null
                        }
                        {
                            this.state.selected === 'Sell' ?
                                <div>
                                    <Title style={{ marginBottom: 40 }}>Seller Settings</Title>
                                    <SettingSection>
                                        <Label>Do you want to sell other brands products on your store?</Label>
                                        <Switch
                                            checked={isSeller}
                                            onChange={checked => this.setState({ isSeller: checked })}
                                        />
                                        {
                                            isSeller ?
                                                <SettingSection>
                                                    <Label>Your Shopify Account</Label>
                                                    {
                                                        !shop ?
                                                            <Button
                                                                style={{ width: isMobile ? '100%' : '30%' }}
                                                                onClick={() => this.setState({ shopifyModalVisible: true })}
                                                            >
                                                                Connect Shopify Store
                                                        </Button>
                                                            :
                                                            <SettingSection>
                                                                <Subtitle>
                                                                    {shop.url} connected
                                                            </Subtitle>
                                                            </SettingSection>

                                                    }
                                                    <SettingSection>
                                                        <Label>Add Credit Card (for non USD payouts)</Label>
                                                        {customerCard ?

                                                            <p>
                                                                {customerCard.brand} ending in {customerCard.last4} {customerCard.exp_month}/{customerCard.exp_year}
                                                                <button
                                                                    style={{ color: 'blue', marginLeft: 10, background: 'none', border: 'none' }}
                                                                    onClick={() => this.setState({ cardModalVisible: true })}
                                                                >
                                                                    Replace
                                                                </button>
                                                            </p>
                                                            : <Button
                                                                onClick={() => this.setState({ cardModalVisible: true })}
                                                                style={{ width: isMobile ? '100%' : '30%' }}
                                                            >
                                                                Add Card
                                                        </Button>
                                                        }
                                                        {/* <Label>Add Bank (for USD payouts)</Label>
                                                        {
                                                            customerBank ?

                                                                <p>
                                                                    {customerBank.bank_name} ending in {customerBank.last4}
                                                                    {
                                                                        customerBank.status !== 'verified' ?
                                                                            <button
                                                                                style={{ color: 'blue', marginLeft: 10, background: 'none', border: 'none' }}
                                                                                onClick={() => this.setState({ verifyBankModalVisible: true })}
                                                                            >
                                                                                Verify
                                                                                </button> :
                                                                            null
                                                                    }
                                                                    <button
                                                                        style={{ color: 'blue', marginLeft: 10, background: 'none', border: 'none' }}
                                                                        onClick={() => this.onAddBank()}
                                                                    >
                                                                        Replace
                                                                    </button>
                                                                </p>
                                                                : <Button
                                                                    loading={loadingPlaidTokenLink}
                                                                    onClick={() => this.onAddBank()}
                                                                    style={{ width: isMobile ? '100%' : '30%' }}
                                                                >
                                                                    Connect Bank
                                                            </Button>
                                                        }
                                                    */}

                                                    </SettingSection>
                                                </SettingSection>
                                                : null
                                        }

                                    </SettingSection>
                                </div>
                                : null
                        }
                        {
                            this.state.selected === 'Brand' ?
                                <div>
                                    <Title style={{ marginBottom: 40 }}>Brand Settings</Title>
                                    <SettingSection>
                                        <Label>Do you want partner to sell your products on other brands stores?</Label>
                                        <Switch
                                            checked={isBrand}
                                            onChange={checked => this.setState({ isBrand: checked })}
                                        />
                                        {
                                            isBrand ?
                                                <SettingSection>
                                                    <Label>Your Shopify Account</Label>
                                                    {
                                                        !shop ?
                                                            <Button
                                                                style={{ width: isMobile ? '100%' : '30%' }}
                                                                onClick={() => this.setState({ shopifyModalVisible: true })}

                                                            >
                                                                Connect Shopify Store
                                                        </Button>
                                                            :
                                                            <SettingSection>
                                                                <Subtitle>
                                                                    {shop.url} connected
                                                            </Subtitle>

                                                            </SettingSection>

                                                    }
                                                    <SettingSection>
                                                        <Label>{logo ? 'Logo' : 'Upload Logo'}</Label>
                                                        <LogoPicker
                                                            logo={logo}
                                                            onClick={() => this.uploadLogo()}
                                                        />
                                                    </SettingSection>
                                                    <SettingSection>
                                                        <Label>Brand Description</Label>
                                                        <TextArea
                                                            placeholder='Brand Description'
                                                            type='brandDescription'
                                                            value={brandDescription}
                                                            onChange={e => this.setState({ brandDescription: e.target.value })}
                                                        />
                                                    </SettingSection>
                                                    {
                                                        dbUser && dbUser.shop && !dbUser.shop.isShopifyPaymentsEnabled ?
                                                            <SettingSection>
                                                                <Label>Get Paid Through Stripe</Label>
                                                                <Button
                                                                    onClick={() => this.props.getAccountLink()}
                                                                    style={{ width: isMobile ? '100%' : '30%' }}
                                                                    loading={loadingAccountLink}
                                                                >
                                                                    {dbUser.account && dbUser.account.charges_enabled ? 'Edit Stripe' : 'Connect Stripe'}
                                                                </Button>
                                                            </SettingSection>
                                                            : null
                                                    }
                                                    <SettingSection>
                                                        <Label>Support Email</Label>
                                                        <Input
                                                            placeholder='Support Email'
                                                            type='supportEmail'
                                                            value={supportEmail}
                                                            onChange={e => this.setState({ supportEmail: e.target.value })}
                                                        />
                                                    </SettingSection>
                                                    <SettingSection
                                                        data-tip='Enter a link to your media kit or other assets to share with partners'
                                                    >
                                                        <Label>Media Files</Label>
                                                        <Input
                                                            placeholder='Media Files'
                                                            type='mediaFilesLink'
                                                            value={mediaFilesLink}
                                                            onChange={e => this.setState({ mediaFilesLink: e.target.value })}
                                                        />
                                                    </SettingSection>
                                                    <ReactTooltip place="top" type="dark" effect="float" />

                                                </SettingSection>
                                                : null
                                        }

                                    </SettingSection>
                                </div>
                                : null
                        }
                    </Card>
                    <Modal
                        visible={shopifyModalVisible}
                        onCancel={() => this.setState({ shopifyModalVisible: false })}
                        onOk={() => this.connectShopify()}
                        okText='Connect Shopify'
                    >
                        <Label>What is your Shopify Url (without https/https) ?</Label>
                        <Input
                            placeholder='Shopify Url'
                            value={shopifyShopUrl}
                            onChange={(e) => this.setState({ shopifyShopUrl: e.target.value })}
                        />
                        <Input
                            placeholder='Shopify API Password'
                            value={shopifyAPIPassword}
                            onChange={(e) => this.setState({ shopifyAPIPassword: e.target.value })}
                        />
                        <a
                            rel="noopener noreferrer"
                            target='_blank'
                            href='https://joinally.freshdesk.com/support/solutions/articles/64000111876-connecting-shopify-as-a-brand-'
                        >
                            Don't have a API Password? click here
                    </a>
                        {
                            scopeInstructions.length > 0 ?
                                <Alert
                                    message="Error connecting, you need to enable more permissions:"
                                    type="error"
                                    description={
                                        scopeInstructions.map((scope, index) => <p>{index + 1}) {scope.scope} access missing. {scope.instruction}</p>)
                                    }
                                />
                                : null

                        }

                    </Modal>
                    <Modal
                        visible={createInviteLinkModal}
                        onOk={() => this.createLink()}
                        onCancel={() => this.setState({ createInviteLinkModal: false })}
                        okText='Create'
                        title='Create Invite Link'
                        okButtonProps={{
                            loading: creatingLink || loadingLinks
                        }}
                    >

                        <FormItem>
                            <Label>Are you the brand or seller?</Label>
                            <Radio.Group
                                onChange={(e) => this.setState({ newInviteLinkType: e.target.value })}
                                value={newInviteLinkType}
                            >
                                <Radio value={'brand'}>Brand</Radio>
                                <Radio value={'seller'}>Seller</Radio>
                            </Radio.Group>
                        </FormItem>
                        <FormItem>
                            <Label>What % will the seller receive per sale?</Label>
                            <Input
                                placeholder='Fee'
                                value={newInviteLinkFee}
                                onChange={(e) => this.setState({ newInviteLinkFee: e.target.value })}
                            />
                        </FormItem>
                    </Modal>
                    <Modal
                        width='50%'
                        visible={inviteLinksModal}
                        onOk={() => this.setState({ inviteLinksModal: false })}
                        onCancel={() => this.setState({ inviteLinksModal: false })}
                        title='Invite Links'
                        footer={
                            <Button
                                onClick={() => this.setState({ createInviteLinkModal: true })}
                            >
                                Create Link
                            </Button>
                        }
                    >
                        <span>
                            Create and share links for partners to signup with. When a partner signs up with your link a partnership will already be formed at the set %, and your stores will be ready to sync.
                        </span>
                        <Table
                            dataSource={links}
                            columns={[
                                {
                                    title: 'Copy Link',
                                    render: (text, record) => <CopyInput value={`${INVITE_URL}/${record.code}`}/>
                                },
                                {
                                    title: 'Fee',
                                    dataIndex: 'fee',
                                    key: 'fee',
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'inviteType',
                                    key: 'inviteType',
                                },
                            ]}
                        />

                    </Modal>

                    <AddCard
                        visible={cardModalVisible}
                        onCancel={() => this.setState({ cardModalVisible: false })}
                        onOk={(token) => this.addCard(token, 'card')}
                        okButtonProps={{ loading: loadingAddCard }}
                    />
                    <AddBank
                        visible={bankModalVisible}
                        onCancel={() => this.setState({ bankModalVisible: false })}
                        onOk={() => this.setState({ bankModalVisible: false })}
                        plaidLinkToken={plaidLinkToken}
                        loading={loadingStripeAccountWithPlaidLoading}
                        onPlaidSuccess={(publicToken, accountId) => this.onPlaidSuccess(publicToken, accountId)}
                        openManualBankModal={() => this.setState({ manualBankModalVisible: true, bankModalVisible: false })}
                    />
                    <AddManualBank
                        visible={manualBankModalVisible}
                        onCancel={() => this.setState({ manualBankModalVisible: false })}
                        onOk={(token) => this.addCard(token, 'bank_account')}
                        okButtonProps={{ loading: loadingAddCard }}

                    />
                    <VerifyBank
                        visible={verifyBankModalVisible}
                        onCancel={() => this.setState({ verifyBankModalVisible: false })}
                        onOk={(amountOne, amountTwo) => this.verifyBank(amountOne, amountTwo)}
                        okButtonProps={{ loading: loadingVerifyBank }}

                    />
                </Container>
            </Elements>
        )
    }
}

export default withRouter(connectScreen(Settings)) 