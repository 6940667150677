  export default [
    {
        path: '/discover',
        name: 'Discover'
    },
    {
        path: '/reports',
        name: 'Reports'
    },
    {
        path: '/partners',
        name: 'Partners'
    },
    {
        path: '/settings',
        name: 'Settings'
    }
]