import axios from 'axios';
import {
    LINK_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_LINKS_LOADING,
    GET_LINKS
} from '../types';
import { message } from 'antd';

export default () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_LINKS_LOADING,
                linksLoading: true
            })
            const { token } = getState().UserReducer;
            const linksResponse = await axios.get(`${LINK_API}`, API_HEADER(token))
            dispatch({
                type: GET_LINKS,
                linksLoading: false,
                links: linksResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_LINKS_LOADING,
                linksLoading: false
            })

        }
    }
}