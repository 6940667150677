import axios from 'axios';
import {
    LINK_API,
    API_HEADER
} from '../../../constants/API'
import {
    CREATE_PARTNERSHIP_WITH_LINK_LOADING
} from '../types';
import { message } from 'antd';

export default (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_PARTNERSHIP_WITH_LINK_LOADING,
                creatingPartnershipWithLink: true
            })
            const { token, dbUser } = getState().UserReducer;
            const postData = { ...data, userId: dbUser.id }
            await axios.post(`${LINK_API}/partnership`, postData, API_HEADER(token))
            dispatch({
                type: CREATE_PARTNERSHIP_WITH_LINK_LOADING,
                creatingPartnershipWithLink: false
            })
            return true;

        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }            
            dispatch({
                type: CREATE_PARTNERSHIP_WITH_LINK_LOADING,
                creatingPartnershipWithLink: false
            })
            return false

        }
    }
}