import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    UPDATE_USER_LOADING,
    UPDATE_USER
} from '../types';
import { message } from 'antd';
import { updateHelpHeroUser } from './setUserState';

export default (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_USER_LOADING,
                updatingUser: true
            });
            const { token, dbUser } = getState().UserReducer;
            const updatedUser = await axios.put(`${USERS_API}/${dbUser.id}`, data, API_HEADER(token));
            dispatch({
                type: UPDATE_USER,
                updatingUser: false,
                dbUser: { ...dbUser, ...updatedUser.data}
            })
            updateHelpHeroUser(updatedUser.data);
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: UPDATE_USER_LOADING,
                updatingUser: false
            })
        }
    }
}