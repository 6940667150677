import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Radio, message, Switch, Tooltip, PageHeader } from 'antd';
import styled from 'styled-components';
import queryString from 'query-string';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
//import Submenu from '../components/Submenu';
import Button from '../components/Button';
//import Card from '../components/Card';
import Label from '../components/Label';
import Dropdown from '../components/Dropdown';
import Input from '../components/Input';
import PartnersTable from '../components/PartnersTable';

// const StatusContainer = styled.div`

// `
const FormItem = styled.div`
    margin-bottom: 20px;
`

class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'Synced',
            addPartnerModalVisible: false,
            fee: '',
            code: '',
            type: 'seller',
            selectedRecord: {},
            shouldSyncProduct: false,
            shouldSyncVariant: false,
            shippingLabel: '',
            search: ''
        }
    }
    componentDidMount() {
        this.getPartnerships();
        this.checkAddPartnerDefaults();
    }
    async getPartnerships() {
        const { search } = this.state;
        // synced, syncing, pending, invited
        this.props.getPartnerships(search);
    }
    setStatus(selected) {
        this.setState({
            selected
        }, () => this.getPartnerships())
    }
    checkAddPartnerDefaults() {
        const {
            code,
            type
        } = queryString.parse(window.location.search);
        if (code && type) {
            this.setState({
                code,
                type,
                addPartnerModalVisible: true
            })
        }
    }
    async acceptPartnership(record, forceAccept = false) {
        const { inviteType } = record;
        //invite type is the person who sent the invite (already inputted daya)
        if (inviteType === 'brand' || forceAccept) {
            //accept it and start syncing
            await this.props.acceptPartnership(record.id);
            this.props.history.push(`/partnership/${record.id}/products`);
        } else {
            //ask for extra info if, it's the seller who invited we need data from the brand
            this.setState({
                acceptModalVisible: true,
                selectedRecord: record
            }, () => {
                this.props.getShippingZones()
            })
        }
    }
    async saveShippingLabel(record) {
        const { shippingLabel } = this.state;
        const partnership = await this.props.updatePartnership(record.id, { shippingLabel });
        if (partnership) {
            this.setState({
                acceptModalVisible: false
            },
                () => this.acceptPartnership(partnership, true)
            )
        } else {
            message.error('Error updating partnership')
        }

    }
    async declinePartnership(record) {
        await this.props.updatePartnership(record.id, { status: 'Cancelled' });
        await this.getPartnerships();
    }
    cancelPartnership(record) {
        message.warn('Cancelling in background - check cancellated tab.')
        this.props.cancelPartnership(record.id)
    }

    async addPartner() {
        const {
            code,
            fee,
            type,
            shouldSyncProduct,
            //         shouldSyncVariant,
            shippingLabel
        } = this.state;
        if (code === '') {
            message.error('You must enter a code')
            return
        }
        if (isNaN(fee) || fee === '') {
            message.error('Fee must be a number')
            return
        }
        if (type !== 'brand' && type !== 'seller') {
            message.error('You must select brand or seller');
            return
        }
        const createdPartnership = await this.props.createPartnership({
            code,
            fee,
            type,
            shouldSyncProduct,
            shouldSyncVariant: true, //DISABLE
            shippingLabel
        });
        if (createdPartnership) {
            this.setState({ addPartnerModalVisible: false });
            this.setStatus('Invited')
        }
    }
    onSearch(search) {
        this.setState({
            search
        }, () => this.getPartnerships())
    }

    render() {
        const { partnerships, partnershipsLoading, creatingPartnership } = this.props.partnership;
        const { dbUser, loadingAccountLink } = this.props.user;
        const { shopifyShippingZones } = this.props.shopify;
        const {
            addPartnerModalVisible,
            fee,
            code,
            type,
            acceptModalVisible,
            selectedRecord,
            shouldSyncProduct,
            //      shouldSyncVariant,
            shippingLabel,
        } = this.state;
        console.log({ shopifyShippingZones });
        console.log({ dbUser });
        console.log({ partnerships });
        return (
            <Container>
                {/* <Submenu
                    menuItems={['Synced', 'Syncing', 'Invited', 'Pending', 'Cancelled']}
                    onSelect={(selected) => this.setStatus(selected)}
                    selected={this.state.selected}
                    footer={
                        <Button
                            style={{ width: 150, height: 40, padding: 0 }}
                            onClick={() => {
                                this.setState({ addPartnerModalVisible: true })
                                this.props.getShippingZones()
                            }}
                        >
                            Add Partner
                        </Button>
                    }
                />
                */}
                <PageHeader
                    title='Your Partners'
                    extra={[
                        <Input
                            placeholder='Search partner'
                            onChange={(e) => this.onSearch(e.target.value)}
                            style={{ width: 150, height: 40 }}
                        />,
                        <Button
                            style={{ width: 150, height: 40, padding: 0 }}
                            onClick={() => {
                                this.setState({ addPartnerModalVisible: true })
                                this.props.getShippingZones()
                            }}
                        >
                            Add Partner
                        </Button>
                    ]}
                />

                <PartnersTable
                    data={partnerships}
                    loading={partnershipsLoading}
                    userId={dbUser.id}
                    showSyncing
                    acceptTitle={'Accept'}
                    acceptDesc={(record) => `The fee set for this partnership is ${record.fee}%. Would you like accept?`}
                    onAcceptClick={(record) => this.acceptPartnership(record)}
                    onDeclineClick={(record) => this.declinePartnership(record)}
                    declineTitle={'Decline'}
                    declineDesc={() => 'Decline is permanent. You or the partner will need to resend an invite to redo this.'}
                    partnerLink={(record) => `partnership/${record.id}`}
                    partnerLinkText={(record) => `Manage`}
                />
                {/*
                     <Card>
                    {this.state.selected === 'Syncing' ?
                        <StatusContainer>
                            <Label>Partners being synced</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}
                                showSyncing
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Invited' ?
                        <StatusContainer>
                            <Label>Invites you have sent to other partners</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Pending' ?
                        <StatusContainer>
                            <Label>Invites you have received from other partners</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                acceptTitle={'Accept'}
                                acceptDesc={(record) => `The fee set for this partnership is ${record.fee}%. Would you like accept?`}
                                onAcceptClick={(record) => this.acceptPartnership(record)}
                                onDeclineClick={(record) => this.declinePartnership(record)}
                                declineTitle={'Decline'}
                                userId={dbUser.id}
                                declineDesc={() => 'Decline is permanent. You or the partner will need to resend an invite to redo this.'}
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Synced' ?
                        <StatusContainer>
                            <Label>Partners you have synced</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}
                                partnerLink={(record) => `partnership/${record.id}`}
                                partnerLinkText={(record) => `Manage`}

                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'c' ?
                        <StatusContainer>
                            <Label>Partners you have cancelled</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}

                            />
                        </StatusContainer>
                        : null
                    }
                </Card>

                */}



                <Modal
                    title='Add a partner'
                    visible={addPartnerModalVisible}
                    onCancel={() => this.setState({ addPartnerModalVisible: false })}
                    onOk={() => this.addPartner()}
                    okText='Invite Partner'
                    okButtonProps={{ loading: creatingPartnership }}
                >
                    <FormItem>
                        <Label>Are you the brand or seller?</Label>
                        <Radio.Group
                            onChange={(e) => this.setState({ type: e.target.value })}
                            value={type}
                        >
                            <Radio value={'brand'}>Brand</Radio>
                            <Radio value={'seller'}>Seller</Radio>
                        </Radio.Group>
                    </FormItem>
                    {
                        type === 'brand' ?
                            <div>
                                <Tooltip
                                    title="Setting a preferred shipping will force all orders created through ALLY to ship through the preferred shipping method."
                                >
                                    <FormItem>
                                        <Label>Set a preferred shipping method (optional)</Label>
                                        <Dropdown
                                            data={shopifyShippingZones}
                                            placeholder='Override Shipping Method'
                                            value={shippingLabel}
                                            onChange={(value) => this.setState({ shippingLabel: value })}
                                        />
                                    </FormItem>
                                </Tooltip>
                            </div>
                            : null
                    }
                    <FormItem>
                        <Label>What is the partners invite code (found in partners Settings Tab)?</Label>
                        <Input
                            placeholder='code'
                            value={code}
                            onChange={(e) => this.setState({ code: e.target.value })}
                        />
                    </FormItem>
                    <FormItem>
                        <Label>What % will the seller receive per sale?</Label>
                        <Input
                            placeholder='Fee'
                            value={fee}
                            onChange={(e) => this.setState({ fee: e.target.value })}
                        />
                    </FormItem>


                    <FormItem>
                        <Label>Should we automatically update product changes from the brand to the sellers shop (title, body, images)?</Label>
                        <Switch
                            checked={shouldSyncProduct}
                            onChange={(checked) => this.setState({ shouldSyncProduct: checked })}
                        />
                    </FormItem>
                    {/* DISABLED<FormItem>
                    <Label>Should we automatically update variant changes from the brand to the sellers shop (price, cost)?</Label>
                        <Switch
                            checked={shouldSyncVariant}
                            onChange={(checked) => this.setState({ shouldSyncVariant: checked })}
                        />
                   </FormItem> */}

                </Modal>
                <Modal
                    title='Do you want to override shipping'
                    visible={acceptModalVisible}
                    onCancel={() => this.setState({ acceptModalVisible: false })}
                    onOk={() => this.saveShippingLabel(selectedRecord)}
                    okText={'Accept'}
                >
                    <Tooltip
                        title="Setting a preferred shipping will force all orders created through ALLY to ship through the preferred shipping method."
                    >
                        <FormItem>
                            <Label>Set a preferred shipping method (optional)</Label>
                            <Dropdown
                                data={shopifyShippingZones}
                                placeholder='Override Shipping Method'
                                value={shippingLabel}
                                onChange={(value) => this.setState({ shippingLabel: value })}
                            />
                        </FormItem>
                    </Tooltip>
                </Modal>
                <Modal
                    title='Action Required: Connect your Stripe Account'
                    visible={
                        dbUser &&
                        dbUser.isBrand &&
                        dbUser.shop &&
                        !dbUser.shop.isShopifyPaymentsEnabled &&
                        dbUser.account &&
                        !dbUser.account.charges_enabled
                    }
                    footer={null}
                >
                    Before you add a partner, we need you to link your bank information via Stripe so you can get paid.
                    <Button
                        onClick={() => this.props.getAccountLink()}
                        style={{ marginTop: 20 }}
                        loading={loadingAccountLink}
                    >
                        Connect Stripe
                    </Button>
                </Modal>
            </Container>
        )
    }
}

export default withRouter(connectScreen(Partners));