import {
    LOGIN_LOADING,
    UPDATE_USER_LOADING,
    UPDATE_USER,
    GET_USERS_LOADING,
    GET_USERS,
    GET_DISCOVER_USER_LOADING,
    GET_DISCOVER_USER,
    GET_ACCOUNT_LINK_LOADING,
    ADD_STRIPE_ACCOUNT_WITH_PLAID_LOADING,
    ADD_CARD_LOADING,
    GET_PLAID_TOKEN_LINK_LOADING,
    GET_PLAID_TOKEN_LINK,
    VERIFY_BANK_LOADING,
    ADD_SPREEDLY_CARD_LOADING
} from '../actions/types';
const defaultState = {
    loginLoading: true,
    isLoggedIn: false,
    fbUser: {},
    dbUser: {
        company: '',
        isBrand: false,
        isSeller: false,
        shop: {
            url: 'menan.myshopify.com'
        },
        code: '21f13',
        payoutEmail: '',
        payoutClientId: '',
        payoutSecretId: ''
    },
    updatingUser: false,
    loadingUsers: false,
    users: [],
    discoverUser: {},
    loadingDiscoverUser: false,
    loadingAccountLink: false,
    loadingAddCard: false,
    loadingStripeAccountWithPlaidLoading: false,
    plaidTokenLink: null,
    loadingPlaidTokenLink: false,
    loadingVerifyBank: false,
    loadingAddSpreedlyCard: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.loginLoading,
                isLoggedIn: action.isLoggedIn,
                dbUser: action.dbUser,
                fbUser: action.fbUser,
                token: action.token
            }
        case UPDATE_USER_LOADING:
            return {
                ...state,
                updatingUser: action.updatingUser
            }
        case UPDATE_USER:
            return {
                ...state,
                updatingUser: action.updatingUser,
                dbUser: action.dbUser
            }
        case GET_USERS_LOADING:
            return {
                ...state,
                loadingUsers: action.loadingUsers
            }
        case GET_USERS:
            return {
                ...state,
                loadingUsers: action.loadingUsers,
                users: action.users
            }
        case GET_DISCOVER_USER_LOADING:
            return {
                ...state,
                loadingDiscoverUser: action.loadingDiscoverUser
            }
        case GET_DISCOVER_USER:
            return {
                ...state,
                loadingDiscoverUser: action.loadingDiscoverUser,
                discoverUser: action.discoverUser
            }
        case GET_ACCOUNT_LINK_LOADING:
            return {
                ...state,
                loadingAccountLink: action.loadingAccountLink
            }
        case ADD_CARD_LOADING:
            return {
                ...state,
                loadingAddCard: action.loadingAddCard
            }
        case ADD_SPREEDLY_CARD_LOADING:
            return {
                ...state,
                loadingAddSpreedlyCard: action.loadingAddSpreedlyCard
            }
        case ADD_STRIPE_ACCOUNT_WITH_PLAID_LOADING:
            return {
                ...state,
                loadingStripeAccountWithPlaidLoading: action.loadingStripeAccountWithPlaidLoading
            }
        case GET_PLAID_TOKEN_LINK_LOADING:
            return {
                ...state,
                loadingPlaidTokenLink: action.loadingPlaidTokenLink
            }
        case GET_PLAID_TOKEN_LINK:
            return {
                ...state,
                loadingPlaidTokenLink: action.loadingPlaidTokenLink,
                plaidLinkToken: action.plaidLinkToken
            }
        case VERIFY_BANK_LOADING:
            return {
                ...state,
                loadingVerifyBank: action.loadingVerifyBank
            }
        default:
            return state;
    }
}