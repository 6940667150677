import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import connectScreen from '../../redux/connectScreen';
import OnboardingContainer from '../../components/OnboardingContainer';
import OnboardingSideBar from '../../components/OnboardingSideBar';
import OnboardingContent from '../../components/OnboardingContent';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';
import LogoPicker from '../../components/LogoPicker';
import TextArea from '../../components/TextArea';
import OnboardingButtonContainer from '../../components/OnboardingButtonContainer';
import { message } from 'antd';

const SettingSection = styled.div`
    margin-top: 20px;
`
class Profile extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            company: dbUser && dbUser.company ? dbUser.company : '',
            logo: dbUser && dbUser.logo ? dbUser.logo : null,
            mediaFilesLink: dbUser && dbUser.mediaFilesLink ? dbUser.mediaFilesLink : '',
            supportEmail: dbUser && dbUser.supportEmail ? dbUser.supportEmail : '',
            brandDescription: dbUser && dbUser.brandDescription ? dbUser.brandDescription : '',
        }
    }

    async uploadLogo() {
        //uploads to cloudinary and saves url to state
        const {
            REACT_APP_CLOUDINARY,
            REACT_APP_CLOUDINARY_UPLOAD
        } = process.env;
        window.cloudinary.openUploadWidget({
            cloudName: REACT_APP_CLOUDINARY,
            uploadPreset: REACT_APP_CLOUDINARY_UPLOAD
        }, (error, result) => {
            if (result && result.event === "success") {
                console.log(result)
                //Get Image uploaded
                const { secure_url, original_filename } = result.info;
                //Add contracts to state
                this.setState({
                    logo: secure_url,
                    logoFileName: original_filename
                })
            }
        });
    }

    async saveUser() {
        const {
            company,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        } = this.state;
        await this.props.updateUser({
            company,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription,
            onboardingStep1Finished: true,
            isBrand: true
        })

    }

    async onNext() {
        const {
            company,
            supportEmail,
            logo,
            brandDescription
        } = this.state;
        if (
            company !== '' &&
            supportEmail !== '' &&
            logo !== '' &&
            brandDescription !== ''
        ) {
            //Savee
            await this.saveUser();
            //go to next page    
            this.props.history.push("/onboarding/shopify"); 
        } else {
            message.error('Please fill your company, logo, brand description and support email to continue')
        }
    }
    render() {
        const {
            company,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        } = this.state;
        return (
            <OnboardingContainer>

                <OnboardingSideBar
                    title='Create your account'
                    subtitle='Create your account in a few steps and start selling your products'
                />
                <OnboardingContent
                    title='Tell Us About Your Brand'
                    subtitle='Some basic details to get started'
                    percent={25}

                >
                    <SettingSection>
                        <SettingSection>
                            <Label>{logo ? 'Logo' : 'Upload Logo'}</Label>
                            <LogoPicker
                                logo={logo}
                                onClick={() => this.uploadLogo()}
                            />
                        </SettingSection>
                        <SettingSection>
                            <Input
                                placeholder='Brand Name'
                                type='company'
                                value={company}
                                onChange={e => this.setState({ company: e.target.value })}
                            />
                        </SettingSection>

                        <SettingSection>
                            <Input
                                placeholder='Support Email'
                                type='supportEmail'
                                value={supportEmail}
                                onChange={e => this.setState({ supportEmail: e.target.value })}
                            />
                        </SettingSection>
                        <SettingSection
                            data-tip='Enter a link to your media kit or other assets to share with partners'
                        >
                            <Input
                                placeholder='Media Files'
                                type='mediaFilesLink'
                                value={mediaFilesLink}
                                onChange={e => this.setState({ mediaFilesLink: e.target.value })}
                            />
                        </SettingSection>
                        <ReactTooltip place="top" type="dark" effect="float" />
                        <SettingSection>
                            <TextArea
                                placeholder='Brand Description'
                                type='brandDescription'
                                value={brandDescription}
                                onChange={e => this.setState({ brandDescription: e.target.value })}
                            />
                        </SettingSection>
                    </SettingSection>
                    <OnboardingButtonContainer>
                        <Button
                            onClick={() => this.onNext()}
                        >
                            Next
                        </Button>
                    </OnboardingButtonContainer>

                </OnboardingContent>
            </OnboardingContainer>
        )
    }
}

export default withRouter(connectScreen(Profile));