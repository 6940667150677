import React from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import {
    CopyOutlined
} from '@ant-design/icons';

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px 15px;
    outline: 0;
    font-size: 16px;
    border: 1px #E1E6ED solid;
    border-radius: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Text = styled.p`
    margin: 0px;
`


export default (props) => {
    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value)
        message.success(`Copied ${value} to clipboard`)
    };
    return (
        <Container onClick={() => copyToClipboard(props.value)}>
            <Text>
                {props.value}
            </Text>
            <CopyOutlined />
        </Container>
    )
}