import React from 'react';
import OnboardingProfile from '../screens/Onboarding/Profile';
import OnboardingShopify from '../screens/Onboarding/Shopify';
import OnboardingPaypal from '../screens/Onboarding/Paypal';
import OnboardingPaypalSeller from '../screens/Onboarding/PaypalSeller';

export default [
    {
        path: '/onboarding/paypalSeller',
        screen: (props) => <OnboardingPaypalSeller {...props} />,
        exact: true
    },
    {
        path: '/onboarding/paypal',
        screen: (props) => <OnboardingPaypal {...props} />,
        exact: true
    },
    {
        path: '/onboarding/profile',
        screen: (props) => <OnboardingProfile {...props} />,
        exact: true
    },
    {
        path: '/onboarding/shopify',
        screen: (props) => <OnboardingShopify {...props} />,
        exact: true
    },
]