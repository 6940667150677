import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    SYNC_PARTNERSHIP_PRODUCTS_LOADING
} from '../types';
import { message } from 'antd';

export default (id, products) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: SYNC_PARTNERSHIP_PRODUCTS_LOADING,
                syncingPartnershipProducts: true
            })
            const { token } = getState().UserReducer;
            const postData = { id, products }
            const syncProductsResponse = await axios.post(`${PARTNERSHIP_API}/products/${id}/sync`, postData, API_HEADER(token))
            message.success(String(syncProductsResponse.data));
            dispatch({
                type: SYNC_PARTNERSHIP_PRODUCTS_LOADING,
                syncingPartnershipProducts: false
            })
            return true;

        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }            
            dispatch({
                type: SYNC_PARTNERSHIP_PRODUCTS_LOADING,
                syncingPartnershipProducts: false
            })
            return false

        }
    }
}