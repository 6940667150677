import React, { useState } from 'react';
import { Modal } from 'antd';
import Input from './Input';

export default ({ visible, onCancel, onOk, okButtonProps }) => {
    const [amountOne, setAmountOne] = useState('');
    const [amountTwo, setAmountTwo] = useState('');

    return (
        <Modal
            title='Verify Bank (ONLY USD)'
            visible={visible}
            onCancel={() => onCancel()}
            onOk={() => onOk(amountOne, amountTwo)}
            okText='Verify Bank'
            okButtonProps={okButtonProps}
        >
            <p>
                Manual verifcation can take up to 7 days. Stripe will automatically depoist 2 small random amounts. Enter the 2 amounts below
            </p>
            <Input
                placeholder='Amount 1'
                value={amountOne}
                onChange={e => setAmountOne(e.target.value)}
            />
            <Input
                placeholder='Amount 2'
                type='number'
                value={amountTwo}
                onChange={e => setAmountTwo(e.target.value)}
            />
    
        </Modal>
    );
};