import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { message } from 'antd';
import connectScreen from '../../redux/connectScreen';
import OnboardingContainer from '../../components/OnboardingContainer';
import OnboardingSideBar from '../../components/OnboardingSideBar';
import OnboardingContent from '../../components/OnboardingContent';
import Button from '../../components/Button';
import OnboardingButtonContainer from '../../components/OnboardingButtonContainer';
import CloseButton from '../../components/CloseButton';
import Label from '../../components/Label';
import Input from '../../components/Input';

const SettingSection = styled.div`
    margin-top: 10px;
`
const TextLink = styled.a`
    width: 100%;
    text-align: center;
    margin-top: 10px;
`
class Profile extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            payoutClientId: dbUser && dbUser.payoutClientId ? dbUser.payoutClientId : '',
            payoutSecretId: dbUser && dbUser.payoutSecretId ? dbUser.payoutSecretId : '',
        }
    }

    async saveUser() {
        const { payoutSecretId, payoutClientId } = this.state;

        await this.props.updateUser({
            onboardingStep4Finished: true,
            hasOnboarded: true,
            payoutSecretId,
            payoutClientId
        })

    }


    async onNext() {
        //Savee
        const { payoutSecretId, payoutClientId } = this.state;
        if (
            payoutSecretId !== '' &&
            payoutClientId !== ''
        ) {
            await this.saveUser();
        } else {
            message.error('Please enter your shopify url and API password')
        }
    }
    render() {
        const {
            payoutClientId,
            payoutSecretId
        } = this.state;
        return (
            <OnboardingContainer>

                <OnboardingSideBar
                    title='Create your account'
                    subtitle='Create your account in a few steps and start selling your products'
                />
                <OnboardingContent
                    title='Connect Your Paypal (send money)'
                    subtitle='To send money to your suppliers connect your Paypal account'
                    percent={100}

                >
                    <iframe
                        src="https://www.loom.com/embed/dc7f42bb31cb4463a9e9a217d0d85f0d"
                        frameborder="0"
                        width='100%'
                        height='300px'
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        title='shopifyInstructionsVideo'
                    >
                    </iframe>
                    <TextLink
                        rel="noopener noreferrer"
                        target='_blank'
                        href='https://joinally.freshdesk.com/support/solutions/articles/64000111876-connecting-shopify-as-a-brand-'
                    >
                        or view written instructions here
                    </TextLink>
                    <>
                        <SettingSection>
                            <Label>Paypal API</Label>
                            <Input
                                placeholder='Paypal Client Id'
                                type='payoutClientId'
                                value={payoutClientId}
                                onChange={e => this.setState({ payoutClientId: e.target.value })}
                            />
                        </SettingSection>
                        <SettingSection>
                            <Input
                                placeholder='Paypal Secret'
                                type='payoutSecretId'
                                value={payoutSecretId}
                                style={{ marginTop: 10 }}
                                onChange={e => this.setState({ payoutSecretId: e.target.value })}
                            />
                        </SettingSection>
                    </>
                    <OnboardingButtonContainer>
                        <Button
                            onClick={() => this.onNext()}
                        >
                            Finish
                        </Button>
                        <CloseButton
                            onClick={() => this.props.history.goBack()}
                        >
                            BACK
                        </CloseButton>
                    </OnboardingButtonContainer>

                </OnboardingContent>
            </OnboardingContainer >
        )
    }
}

export default withRouter(connectScreen(Profile));