import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import styled from 'styled-components';
import {
    PageHeader,
    List,
    Card
} from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import connectScreen from '../../redux/connectScreen';
import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import Button from '../../components/Button';

const CardMeta = styled(Card.Meta)`
`
class User extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getUser()
    }

    async getUser() {
        const { id } = this.props.match.params;
        await this.props.getDiscoverUser(id);
    }

    render() {
        const {
            loadingDiscoverUser,
            discoverUser
        } = this.props.user;
        console.log({ discoverUser })
        if (loadingDiscoverUser) {
            return <Container><LoadingSpinner /></Container>
        }
        const {
            company,
            brandDescription,
            products,
            code,
            shop
        } = discoverUser;
        return (
            <Container>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title={company}
                    subTitle={
                        <a
                            href={shop && shop.url ? `https://${shop.url}` : ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#ababab'}}
                        >
                            View site
                        </a>
                    }
                    extra={[
                        <Link
                            to={`/partners?code=${code}&type=brand`}
                        >
                            <Button

                            >
                                Invite To Partner
                            </Button>
                        </Link>,
                    ]}
                >

                    {brandDescription}
                </PageHeader>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 3,
                    }}
                    style={{ marginTop: 20 }}
                    dataSource={products}
                    renderItem={product => (
                        <List.Item>
                            <Card
                                hoverable
                                cover={
                                    <div style={{ border: '1px solid #DFE3E7', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                        <img
                                            alt={product.title}
                                            src={product.images && product.images[0] && product.images[0].src ? product.images[0].src : require('../../images/logo-grey.png')}
                                            style={{ height: 200, maxWidth: '100%', objectFit: 'contain' }}
                                        />
                                    </div>
                                }
                                actions={[
                                    <a
                                        href={shop && shop.url ? `https://${shop.url}/products/${product.handle}` : ''}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ExportOutlined key="ellipsis" />
                                    </a>,
                                ]}
                            >
                                <CardMeta
                                    title={product.title}
                                    style={{ overflow: 'hidden' }}
                                />
                            </Card>
                        </List.Item>
                    )}
                />
            </Container>
        )
    }
}

export default withRouter(connectScreen(User));