import axios from 'axios';
import {
    LINK_API,
    API_HEADER
} from '../../../constants/API'
import {
    UPDATE_LINK_LOADING,
} from '../types';
import { message } from 'antd';

export default (id, data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_LINK_LOADING,
                updatingLink: true
            })
            const { token } = getState().UserReducer;
            const updateLinkRespoonse = await axios.put(`${LINK_API}/${id}`, data, API_HEADER(token));
            dispatch({
                type: UPDATE_LINK_LOADING,
                updatingLink: false,
            })
            return updateLinkRespoonse.data;
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: UPDATE_LINK_LOADING,
                updatingLink: false
            })

        }
    }
}