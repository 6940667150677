import React, { Component } from 'react';
import moment from 'moment';
import {
    Table,
    DatePicker,
    Row,
    Col,
    Statistic,
    Card
} from 'antd';
import styled from 'styled-components';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Label from '../components/Label';
import Submenu from '../components/Submenu';
const { RangePicker } = DatePicker;

const LabelDatePickerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
`

class Reports extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: this.getDefaultSelected(),
            shouldShowBrandAndSeller: dbUser.isBrand && dbUser.isSeller,
            startDate: moment().subtract(1, 'day'),
            endDate: moment()
        }
    }


    componentDidMount() {
        this.getReport();

    }

    getDefaultSelected() {
        const { dbUser } = this.props.user;
        if (!dbUser.isBrand && dbUser.isSeller) {
            //ONLY Seller
            return 'Seller';
        } else {
            // isBrand OR is not both OR is both
            return 'Brand'
        }
    }

    getReport() {
        const { selected, startDate, endDate } = this.state;
        this.props.getPartnershipsReport(selected, startDate, endDate);

    }

    setType(selected) {
        this.setState({
            selected
        }, () => this.getReport())
    }

    setDate(dates) {
        if (dates && dates[0] && dates[1]) {
            this.setState({
                startDate: dates[0],
                endDate: dates[1]
            }, () => this.getReport())
        }
    }

    getColumns() {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        const { selected } = this.state;
        if (selected === 'Brand') {
            if (isMobile) {
                return [
                    {
                        title: 'Partner',
                        dataIndex: 'partner',
                    },
                    {
                        title: 'Order Count',
                        dataIndex: 'orderCount',
                    },
                    {
                        title: 'Net',
                        dataIndex: 'net',
                        render: (text) => <p>${text}</p>
                    },
                ]
            }
            return [
                {
                    title: 'Partner',
                    dataIndex: 'partner',
                },
                {
                    title: 'Order Count',
                    dataIndex: 'orderCount',
                },
                {
                    title: 'Gross',
                    dataIndex: 'gross',
                    render: (text) => <p>${text}</p>
                },
                {
                    title: 'Commission Paid',
                    dataIndex: 'commission',
                    render: (text) => <p>${text}</p>
                },
                {
                    title: 'Net',
                    dataIndex: 'net',
                    render: (text) => <p>${text}</p>
                },
            ]
        } else {
            if (isMobile) {
                return [
                    {
                        title: 'Partner',
                        dataIndex: 'partner',
                    },
                    {
                        title: 'Order Count',
                        dataIndex: 'orderCount',
                    },
                    {
                        title: 'Net',
                        dataIndex: 'net',
                        render: (text) => <p>${text}</p>
                    },
                ]
            }
            return [
                {
                    title: 'Partner',
                    dataIndex: 'partner',
                },
                {
                    title: 'Order Count',
                    dataIndex: 'orderCount',
                },
                {
                    title: 'Gross',
                    dataIndex: 'gross',
                    render: (text) => <p>${text}</p>
                },
                {
                    title: 'Payouts',
                    dataIndex: 'net',
                    render: (text) => <p>${text}</p>
                },
                /*     {
                         title: 'Ally',
                         dataIndex: 'allyCommission',
                         render: (text) => <p>${text}</p>
                     },
    
                     */
                {
                    title: 'Net',
                    dataIndex: 'commission',
                    render: (text, record) => <p>${text}</p>
                },


            ]
        }
    }

    renderBrandReportSummary() {
        const { partnershipReportData, } = this.props.report;
        const { orderCount, net, gross, commission } = partnershipReportData.summary;

        return (
            <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            suffix='orders'
                            title='Order Count'
                            value={orderCount}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Gross'
                            value={gross}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Commission Paid'
                            value={commission}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Net'
                            value={net}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }

    renderSellerReportSummary() {
        const { partnershipReportData, } = this.props.report;
        const { orderCount, net, gross, commission } = partnershipReportData.summary;

        return (
            <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            suffix='orders'
                            title='Order Count'
                            value={orderCount}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Gross'
                            value={gross}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Payout'
                            value={net}
                        />
                    </Card>
                </Col>
                {/*  
                 <Col span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                    >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Ally'
                            value={Number(allyCommission).toFixed(2)}
                        />
                    </Card>
                </Col>
        */}
                <Col
                    span={6}
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                >
                    <Card>
                        <Statistic
                            prefix='$'
                            title='Net'
                            value={commission}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        const { partnershipReportData, partnershipReportDataLoading } = this.props.report;
        const { startDate, endDate, selected, shouldShowBrandAndSeller } = this.state;
        return (
            <Container>
                {
                    shouldShowBrandAndSeller ? <Submenu
                        menuItems={['Brand', 'Seller']}
                        onSelect={(selected) => this.setType(selected)}
                        selected={this.state.selected}
                    />
                        : null
                }
                <LabelDatePickerContainer>
                    <Label>
                        {
                            this.state.selected === 'Brand' ?
                                'Your report on each Ally seller partner'
                                : 'Your report on each Ally brand partner'

                        }
                    </Label>
                    <RangePicker
                        value={[startDate, endDate]}
                        onChange={(dates) => this.setDate(dates)}
                        style={{ marginBottom: 10 }}
                    />
                </LabelDatePickerContainer>
                {
                    selected === 'Brand' ? this.renderBrandReportSummary() : this.renderSellerReportSummary()
                }
                <Table
                    loading={partnershipReportDataLoading}
                    dataSource={partnershipReportData.data}
                    columns={this.getColumns()}
                />

            </Container>
        )
    }
}

export default connectScreen(Reports)