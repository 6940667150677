import React from 'react';
import { message, Modal } from 'antd';
import { PlaidLink } from 'react-plaid-link';
import styled from 'styled-components';
import Button from './Button';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Link = styled.button`
    margin-top: 10px;
    color: blue;
    background: none;
    border: none;
`

export default ({ visible, onCancel, onOk, plaidLinkToken, onPlaidSuccess, loading, openManualBankModal }) => {
    console.log({ plaidLinkToken })

    const onSuccess = (publicToken, metadata) => {
        // send publicToken to server
        console.log(publicToken)
        if (
            metadata &&
            metadata.accounts &&
            metadata.accounts[0]
        ) {
            console.log('Customer-selected account ID: ' + metadata.accounts[0].id);
            onPlaidSuccess(publicToken, metadata.accounts[0].id);
        } else {
            message.error('Connecting your bank failed');
        }
    };

    return (
        <Modal
            title='Add your bank to pay brands (USD payouts)'
            visible={visible}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            okText='Ok'
        >
            <Container>
                <p>Enter and verify a bank account to payout USD based brands.</p>
                {
                    loading ?
                        <Button loading>

                        </Button>
                        :
                        plaidLinkToken ?
                            <PlaidLink
                                token={plaidLinkToken}
                                onSuccess={onSuccess}
                            >
                                <Button>
                                    Automatically Verify
                                    </Button>
                            </PlaidLink>
                            : null
                }
                <Link
                    onClick={openManualBankModal}
                >
                    Not working? Click here to manually verify
                </Link>
            </Container>
        </Modal>
    );
};