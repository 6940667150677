import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    message,
    PageHeader,
    Switch,
    Table,
    Menu,
    Dropdown,
    Input as AntdInput,
    Select,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import connectScreen from '../../redux/connectScreen';
import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import Button from '../../components/Button';
import Input from '../../components/Input';

const ShowVariantMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            brandPayoutVisibleDropdown: false,
            brandPayoutVisibleDropdownType: 'dollar'
        }
    }

    componentDidMount() {
        this.getPartnership();
        this.getPartnershipProducts();
    }
    getPartnership() {
        const { id } = this.props.match.params;
        this.props.getPartnership(id);
    }
    async getPartnershipProducts() {
        const { id } = this.props.match.params;
        await this.props.getPartnershipProducts(id);
        const { partnershipProducts } = this.props.partnership;
        this.setState({
            partnershipProducts
        })
    }
    getPartnerData = () => {
        const { partnership } = this.props.partnership;
        const { dbUser } = this.props.user;
        const { seller, brand } = partnership;
        if (dbUser.id === seller.id) {
            //partner is the brand
            return {
                name: brand.company,
                mediaKit: brand.mediaFilesLink,
                logo: brand.logo,
                supportEmail: brand.supportEmail,
                brandDescription: brand.brandDescription,
                isPartnerBrand: true
            };
        } else {
            //partner is the seller
            return {
                name: seller.company,
                mediaKit: seller.mediaFilesLink,
                logo: seller.logo,
                supportEmail: seller.supportEmail,
                brandDescription: seller.brandDescription,
                isPartnerBrand: false
            };
        }
    }

    updateAllProductShowState(checked) {
        //get partnership products
        const partnershipProducts = this.state.partnershipProducts.map(product => {
            const variants = product.variants.map(variant => {
                return {
                    ...variant,
                    productVariant: {
                        ...variant.productVariant,
                        showVariant: checked
                    }
                }
            })
            return {
                ...product,
                variants
            }
        });


        console.log({
            partnershipProducts,
            checked
        })
        //map and add the show variant 
        this.setState({
            partnershipProducts
        });
    }


    updateProductValue(productIndex, object, key, value, variantKey, shouldUpdateWithValue = false) {

        console.log({
            productIndex, object, key, value
        })
        const { currentPage } = this.state;
        //the item number/ index passed in is just the index of the page
        //the index in the array is page number * number of items on page + position of the item
        const numberOfItemsOnPage = 10;
        const zeroIndexedPageNumber = currentPage - 1;
        const index = (zeroIndexedPageNumber * numberOfItemsOnPage) + productIndex;
        let partnershipProducts = [...this.state.partnershipProducts];


        let partnershipProduct = {
            ...partnershipProducts[index],

        };
        console.log({
            partnershipProduct
        })
        let partnershipProductVariants = partnershipProduct.variants;
        console.log({
            partnershipProductVariants
        })
        //update all the variants of the product
        partnershipProductVariants = partnershipProductVariants.map(variant => {
            if(shouldUpdateWithValue) {
                return {
                    ...variant,
                    [object]: {
                        ...variant[object],
                        [key]: value * variant[variantKey]
                    }
                }
            } else {
                return {
                    ...variant,
                    [object]: {
                        ...variant[object],
                        [key]: value
                    }
                }
            }
            
        })
        partnershipProduct = {
            ...partnershipProduct,
            variants: partnershipProductVariants
        }
        partnershipProducts[index] = partnershipProduct;
        this.setState({ partnershipProducts });
    }

    updateProductVariantValue(variantIndex, productIndex, object, key, value) {

        console.log({
            variantIndex, productIndex, object, key, value
        })
        const { currentPage } = this.state;
        //the item number/ index passed in is just the index of the page
        //the index in the array is page number * number of items on page + position of the item
        const numberOfItemsOnPage = 10;
        const zeroIndexedPageNumber = currentPage - 1;
        const index = (zeroIndexedPageNumber * numberOfItemsOnPage) + productIndex;
        let partnershipProducts = [...this.state.partnershipProducts];


        let partnershipProduct = {
            ...partnershipProducts[index],

        };
        console.log({
            partnershipProduct
        })
        let partnershipProductVariants = partnershipProduct.variants;
        console.log({
            partnershipProductVariants
        })
        partnershipProductVariants[variantIndex] = {
            ...partnershipProductVariants[variantIndex],
            [object]: {
                ...partnershipProductVariants[variantIndex][object],
                [key]: value
            }
        }
        partnershipProduct = {
            ...partnershipProduct,
            variants: partnershipProductVariants
        }
        partnershipProducts[index] = partnershipProduct;
        this.setState({ partnershipProducts });
    }


    selectAllProducts() {
        const menu = (
            <Menu>
                <Menu.Item
                    onClick={() => this.updateAllProductShowState(true)}
                >
                    Select All Variants
                </Menu.Item>
                <Menu.Item
                    onClick={() => this.updateAllProductShowState(false)}
                >
                    Unselect All Variants
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu}>
                <ShowVariantMenu onClick={e => e.preventDefault()}>
                    <span style={{ marginRight: 5 }}>
                        Sync?
                    </span>
                    <MoreOutlined />
                </ShowVariantMenu>
            </Dropdown>
        )
    }

    selectAllProductMenu(productIndex) {
        const menu = (
            <Menu>
                <Menu.Item
                    onClick={() => this.updateProductValue(productIndex, 'productVariant', 'showVariant', true)}
                >
                    Select All Variants
                </Menu.Item>
                <Menu.Item
                    onClick={() => this.updateProductValue(productIndex, 'productVariant', 'showVariant', false)}
                >
                    Unselect All Variants
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu}>
                <ShowVariantMenu onClick={e => e.preventDefault()}>
                    <span style={{ marginRight: 5 }}>
                        Sync?
                    </span>
                    <MoreOutlined />
                </ShowVariantMenu>
            </Dropdown>
        )
    }
    setAllProductBrandPayout(productIndex, isPartnerBrand) {
        const { brandPayoutVisibleDropdownType } = this.state;
        const menu = (
            <Menu>
                <Menu.Item>
                    <AntdInput
                        addonAfter={
                            <Select
                                defaultValue="dollar"
                                onChange={(selected) => this.setState({ brandPayoutVisibleDropdownType: selected })}
                            >
                                <Select.Option value="dollar">$</Select.Option>
                                <Select.Option value="percent">%</Select.Option>
                            </Select>
                        }
                        placeholder='Enter brand payout'
                        type='Number'
                        disabled={isPartnerBrand ? true : false}
                        onChange={e => this.updateProductValue(productIndex, 'productVariant', 'wholesalePrice', brandPayoutVisibleDropdownType === 'percent' ? e.target.value / 100 :  e.target.value, 'price', brandPayoutVisibleDropdownType === 'percent')}
                    />
                </Menu.Item>

            </Menu>
        );
        return (
            <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={this.state['brandPayoutVisibleDropdown' + productIndex]}
                onVisibleChange={(visible) => this.setState({ ['brandPayoutVisibleDropdown' + productIndex]: visible })}
            >
                <ShowVariantMenu onClick={e => e.preventDefault()}>
                    <span style={{ marginRight: 5 }}>
                        Brand Payout
                    </span>
                    <MoreOutlined />
                </ShowVariantMenu>
            </Dropdown>
        )
    }
    setAllProductMapPrice(productIndex, isPartnerBrand) {
        const menu = (
            <Menu>
                <Menu.Item>
                    <AntdInput
                        placeholder='Bulk enter map price'
                        type='Number'
                        disabled={isPartnerBrand ? true : false}
                        onChange={e => this.updateProductValue(productIndex, 'productVariant', 'mapPrice', e.target.value)}

                    />
                </Menu.Item>

            </Menu>
        );
        return (
            <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={this.state['mapPriceVisibleDropdown' + productIndex]}
                onVisibleChange={(visible) => this.setState({ ['mapPriceVisibleDropdown' + productIndex]: visible })}
            >
                <ShowVariantMenu onClick={e => e.preventDefault()}>
                    <span style={{ marginRight: 5 }}>
                        Map Price
                    </span>
                    <MoreOutlined />
                </ShowVariantMenu>
            </Dropdown>
        )
    }
    getProductTableColumns() {
        return [
            {
                title: 'Title',
                key: 'title',
                render: (text, record) =>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <img
                            alt={record.title}
                            style={{ width: 100, marginRight: 10 }}
                            src={record.images && record.images[0] ? record.images[0].src : ''}
                        />
                        <div>
                            <span>{record.title}</span>
                        </div>
                    </div>,
            },
            {
                title: 'Category',
                key: 'category',
                dataIndex: 'product_type'
            },
            {
                title: () => this.selectAllProducts(),
                key: 'showVariant',
                render: (text, record, index) => <Switch
                    checked={record.variants && record.variants.find(variant => variant && variant.productVariant && variant.productVariant.showVariant ? true : false)}
                    onChange={checked => this.updateProductValue(index, 'productVariant', 'showVariant', checked)}
                />,
            },
        ]
    }

    getVariantTableColumns(productIndex) {
        const { partnership } = this.props.partnership;
        const { isPartnerBrand } = this.getPartnerData();
        return [
            {
                title: 'Title',
                key: 'title',
                render: (text, record) => {
                    const images = record.product.images;
                    const variantImage = images.find(image => image.id === record.image_id);

                    return (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img
                                alt={record.title}
                                style={{ width: 50, marginRight: 10 }}
                                src={variantImage && variantImage.src ? variantImage.src : ''}
                            />
                            <div>
                                <span>{record.title}</span>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Price',
                key: 'price',
                render: (text, record) => <span>${record.price}</span>,
            },
            {
                title: 'Inventory',
                key: 'inventory_quantity',
                render: (text, record) => record.inventory_quantity
            },
            {
                title: () => this.setAllProductBrandPayout(productIndex, isPartnerBrand),
                key: 'brandPayout',
                render: (text, record, index) => <Input
                    value={record.productVariant && record.productVariant.wholesalePrice !== null && typeof record.productVariant.wholesalePrice !== 'undefined' ? record.productVariant.wholesalePrice : Number(record.price) * ((100 - Number(partnership.partnership.fee)) / 100)}
                    placeholder='Enter brand payout'
                    type='Number'
                    disabled={isPartnerBrand ? true : false}
                    onChange={e => this.updateProductVariantValue(index, record.productIndex, 'productVariant', 'wholesalePrice', e.target.value)}
                />,
            },
            {
                title: 'Wholesale Price',
                key: 'wholesalePrice',
                render: (text, record, index) => <Input
                    value={record.productVariant && record.productVariant.wholesalePrice !== null && typeof record.productVariant.wholesalePrice !== 'undefined' ? Number(record.price) - Number(record.productVariant.wholesalePrice) : Number(record.price) - (Number(record.price) * ((100 - Number(partnership.partnership.fee)) / 100))}
                    placeholder='Enter wholesale price'
                    type='Number'
                    disabled={true}
                />,
            },
            {
                title: () => this.setAllProductMapPrice(productIndex, isPartnerBrand),
                key: 'mapPrice',
                render: (text, record, index) => <Input
                    value={record.productVariant && record.productVariant.mapPrice !== null && typeof record.productVariant.mapPrice !== 'undefined' ? record.productVariant.mapPrice : record.price}
                    placeholder='Enter map price'
                    type='Number'
                    disabled={isPartnerBrand ? true : false}
                    onChange={e => this.updateProductVariantValue(index, record.productIndex, 'productVariant', 'mapPrice', e.target.value)}

                />,
            },
            {
                title: 'Status',
                key: 'status',
                render: (text, record) => <span>{record.status}</span>,
            },
            {
                title: () => this.selectAllProductMenu(productIndex),
                key: 'showVariant',
                render: (text, record, index) => <Switch
                    checked={record.productVariant ? record.productVariant.showVariant : false}
                    onChange={checked => this.updateProductVariantValue(index, record.productIndex, 'productVariant', 'showVariant', checked)}
                />,
            },
        ]
    }

    syncProducts() {
        const { id } = this.props.match.params;
        const { partnershipProducts } = this.state;
        console.log({
            partnershipProducts
        })
        this.props.syncPartnershipProducts(id, partnershipProducts);
    }


    render() {
        const {
            partnershipProductsLoading,
            partnership,
            partnershipLoading,
            syncingPartnershipProducts
        } = this.props.partnership;
        const { partnershipProducts } = this.state;

        if (partnershipLoading) {
            return <Container><LoadingSpinner /></Container>
        }

        if (!partnershipProducts || !partnership) {
            return <Container><LoadingSpinner /></Container>
        }
        const partnerData = this.getPartnerData();
        const columns = this.getProductTableColumns();
        const {
            name
        } = partnerData;
        console.log({
            partnershipProducts
        })
        return (
            <Container>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title={'Edit Products'}
                    subTitle={`for partnership with ${name}`}
                    extra={[
                        <Button
                            onClick={() => syncingPartnershipProducts ? message.error('Already syncing, please wait...') : this.syncProducts()}
                            loading={syncingPartnershipProducts}
                        >
                            Sync
                        </Button>
                    ]}
                />

                <Table
                    dataSource={partnershipProducts}
                    columns={columns}
                    loading={partnershipProductsLoading}
                    rowKey={'product_id'}
                    showHeader={true}
                    expandable={{
                        expandedRowRender: (record, index) => {
                            const variantColumns = this.getVariantTableColumns(index);
                            return (
                                <Table
                                    columns={variantColumns}
                                    loading={partnershipProductsLoading}
                                    dataSource={record.variants.map(variant => { return { ...variant, product: record, productIndex: index } })}
                                />
                            )
                        }
                    }}

                    pagination={{
                        onChange: (current) => this.setState({ currentPage: current })
                    }}
                />

            </Container>
        );
    }
}

export default connectScreen(withRouter(Products));