import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_USERS_LOADING,
    GET_USERS
} from '../types';
import { message } from 'antd';
import { updateHelpHeroUser } from './setUserState';

export default (query) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_USERS_LOADING,
                loadingUsers: true
            });
            const { token } = getState().UserReducer;
            const getUsersResponse = await axios.get(`${USERS_API}?${query}`, API_HEADER(token));
            dispatch({
                type: GET_USERS,
                loadingUsers: false,
                users: getUsersResponse.data
            })
            updateHelpHeroUser(getUsersResponse.data);
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_USERS_LOADING,
                loadingUsers: false
            })
        }
    }
}