import styled from 'styled-components';

export default styled.div`
    margin-left: 250px;
    padding: 40px 40px 40px 40px;
    max-height: 100vh;
        overflow-y: scroll;
    @media (max-width: 768px) {
        margin-left: 0px;
        padding: 20px;
        padding-top: 20px;
        
    }
`