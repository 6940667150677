import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_PLAID_TOKEN_LINK_LOADING,
    GET_PLAID_TOKEN_LINK
} from '../types';
import { message } from 'antd';

export default () => {
    return async (dispatch, getState) => {
        try {
            const { token } = getState().UserReducer;
            dispatch({
                type: GET_PLAID_TOKEN_LINK_LOADING,
                loadingPlaidTokenLink: true
            });
            const plaidLinkTokenResponse = await axios.get(`${USERS_API}/stripe/plaid`, API_HEADER(token));
            console.log({ plaidLinkTokenResponse })
            dispatch({
                type: GET_PLAID_TOKEN_LINK,
                plaidLinkToken: plaidLinkTokenResponse.data,
                loadingPlaidTokenLink: false
            });
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_PLAID_TOKEN_LINK_LOADING,
                loadingPlaidTokenLink: false
            });
        }
    }
}