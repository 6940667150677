import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { useStripe } from '@stripe/react-stripe-js';
import Input from './Input';

export default ({ visible, onCancel, onOk, okButtonProps }) => {
    const stripe = useStripe();
    const [company, setCompany] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const handleSubmit = async () => {
        // Block native form submission.
        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.

        // Use your card Element with other Stripe.js APIs
        const { error, token } = await stripe.createToken('bank_account', {
            country: 'US',
            currency: 'USD',
            account_holder_name: company,
            account_holder_type: 'company',
            routing_number: routingNumber,
            account_number: accountNumber,
        });
        if (error) {
            console.log('[Error]', error);
            message.error(String(error.message));
        } else {
            console.log('[PaymentMethod]', token);
            onOk(token.id);
        }
    };

    return (
        <Modal
            title='Manually Add Bank (ONLY USD)'
            visible={visible}
            onCancel={() => onCancel()}
            onOk={() => handleSubmit()}
            okText='Add Bank'
            okButtonProps={okButtonProps}
        >
            <p>
                Manual verifcation can take up to 7 days. Stripe will automatically depoist 2 small random amounts, once you receive those amount - you must come back and verify.
            </p>
            <Input
                placeholder='Company'
                value={company}
                onChange={e => setCompany(e.target.value)}
            />
            <Input
                placeholder='Routing Number'
                type='number'
                value={routingNumber}
                onChange={e => setRoutingNumber(e.target.value)}
            />
            <Input
                placeholder='Account Number'
                type='number'
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
            />
        </Modal>
    );
};