import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_ACCOUNT_LINK_LOADING,
} from '../types';
import { message } from 'antd';

export default () => {
    return async (dispatch, getState) => {
        try {
            const { token } = getState().UserReducer;
            dispatch({
                type: GET_ACCOUNT_LINK_LOADING,
                loadingAccountLink: true
            });
            const accountLinkResponse = await axios.get(`${USERS_API}/stripe/accountLink`, API_HEADER(token));
            dispatch({
                type: GET_ACCOUNT_LINK_LOADING,
                loadingAccountLink: false
            });
            window.open(accountLinkResponse.data, '_blank');
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_ACCOUNT_LINK_LOADING,
                loadingAccountLink: false
            });
        }
    }
}