import React, { Component } from 'react';
import firebase from 'firebase';
import { message } from 'antd';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string'
import connectScreen from '../../redux/connectScreen';
import LoadingScreen from '../../components/LoadingScreen';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false
        }
    }
    componentDidMount() {
        this.login();
    }

    async login() {
        try {
            const {
                token
            } = queryString.parse(this.props.location.search);
            console.log({
                token
            })
            await firebase.auth().signInWithCustomToken(token);
        } catch (e) {
            console.log({
                e
            })
            this.setState({ isError: true })
            message.error(e.message)
        }
    }

    render() {
        const { isLoggedIn } = this.props.user;
        const { isError } = this.state;
        if (isLoggedIn) {
            return <Redirect to='/partners' />
        }
        if (isError) {
            return <Redirect to='/login' />
        }
        return (
            <LoadingScreen />
        );
    }
}

export default connectScreen(withRouter(Login));