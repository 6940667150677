import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { PRIMARY } from '../constants/Colors';


const Button = styled.button`
    color: #FFFFFF;
    background-color: ${PRIMARY};
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(78, 155, 245, 0.2), 0px 1px 2px rgba(40, 41, 43, 0.1);
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s ease;
    outline: 0;
`

export default (props) => (
    <Button {...props}>
        {props.loading ?
            <Spinner data-test='loading-button' animation="border" />
            :
            props.children
        }
    </Button>
)