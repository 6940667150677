import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_PARTNERSHIP_LOADING,
    GET_PARTNERSHIP
} from '../types';
import { message } from 'antd';

export default (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PARTNERSHIP_LOADING,
                partnershipLoading: true
            })
            const { token } = getState().UserReducer;
            const partnershipResponse = await axios.get(`${PARTNERSHIP_API}/${id}`, API_HEADER(token))
            dispatch({
                type: GET_PARTNERSHIP,
                partnershipLoading: false,
                partnership: partnershipResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_PARTNERSHIP_LOADING,
                partnershipLoading: false
            })

        }
    }
}