import {
    GET_SHOPIFY_COLLECTION_LOADING,
    GET_SHOPIFY_COLLECTION,
    CHECKING_SCOPES,
    CHECKING_SCOPES_LOADING,
    GET_SHOPIFY_SHIPPING_ZONES,
    GET_SHOPIFY_SHIPPING_ZONES_LOADING
} from '../actions/types';
const defaultState = {
    shopifyCollections: [],
    shopifyCollectionsLoading: false,
    checkingScopes: false,
    scopeInstructions: [],
    shopifyShippingZonesLoading: false,
    shopifyShippingZones: []
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_SHOPIFY_COLLECTION_LOADING:
            return {
                ...state,
                shopifyCollectionsLoading: action.shopifyCollectionsLoading
            }
        case GET_SHOPIFY_COLLECTION:
            return {
                ...state,
                shopifyCollectionsLoading: action.shopifyCollectionsLoading,
                shopifyCollections: action.shopifyCollections
            }
        case CHECKING_SCOPES:
            return {
                ...state,
                checkingScopes: action.checkingScopes,
                scopeInstructions: action.scopeInstructions
            }
        case CHECKING_SCOPES_LOADING:
            return {
                ...state,
                checkingScopes: action.checkingScopes,
            }
        case GET_SHOPIFY_SHIPPING_ZONES:
            return {
                ...state,
                shopifyShippingZones: action.shopifyShippingZones,
                shopifyShippingZonesLoading: action.shopifyShippingZonesLoading
            }
        case GET_SHOPIFY_SHIPPING_ZONES_LOADING:
            return {
                ...state,
                shopifyShippingZonesLoading: action.shopifyShippingZonesLoading
            }
        default:
            return state;
    }
}