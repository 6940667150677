import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    PageHeader,
    Button as AntButton,
    Card,
    Modal,
    Tabs,
    Switch,
    message,
    Tooltip
} from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import connectScreen from '../../redux/connectScreen';
import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import Input from '../../components/Input';
import Label from '../../components/Label';
import LogoPicker from '../../components/LogoPicker';
import CopyInput from '../../components/CopyInput';
import LinkInput from '../../components/LinkInput';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';

const SettingSection = styled.div`
    margin-top: 20px;
`

class Partnership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldShowLoading: true
        }
    }
    componentDidMount() {
        this.getPartnership();
        this.props.getShippingZones()
    }

    getPartnership() {
        const { id } = this.props.match.params;
        this.props.getPartnership(id);
    }
    getPartnerData = () => {
        const { partnership } = this.props.partnership;
        const { dbUser } = this.props.user;
        console.log({
            partnership
        })
        const { seller, brand } = partnership;
        if (dbUser.id === seller.id) {
            //partner is the brand
            return {
                name: brand.company,
                mediaKit: brand.mediaFilesLink,
                logo: brand.logo,
                supportEmail: brand.supportEmail,
                brandDescription: brand.brandDescription,
                isPartnerBrand: true
            };
        } else {
            //partner is the seller
            return {
                name: seller.company,
                mediaKit: seller.mediaFilesLink,
                logo: seller.logo,
                supportEmail: seller.supportEmail,
                brandDescription: seller.brandDescription,
                isPartnerBrand: false
            };
        }
    }


    async cancelPartnership() {
        const { id } = this.props.match.params;
        const { cancellingPartnership } = this.props.partnership;
        Modal.confirm({
            title: 'Are you sure want to cancel?',
            content: 'Cancelling will delete the sync and remove the products between your stores',
            okText: 'Cancel',
            okType: 'danger',
            cancelText: 'No',
            confirmLoading: true,
            okButtonProps: {
                loading: cancellingPartnership,
            },
            onOk: async () => {
                await this.props.cancelPartnership(id);
                this.props.history.push('/discover')
            },
            onCancel() { }
        })
    }


    renderPartnershipDetails(partnerData) {
        const {
            mediaKit,
            logo,
            supportEmail,
        } = partnerData;
        const {
            partnership
        } = this.props.partnership
        return (
            <Card style={{ marginTop: 10 }}>
                <LogoPicker
                    logo={logo}
                    onClick={() => console.log()}
                />
                <SettingSection>
                    <Label>Default Commission</Label>
                    <Input
                        placeholder='Default Commission'
                        value={`${partnership.partnership.fee} %`}
                        disabled
                    />
                </SettingSection>
                <SettingSection>
                    <Label>Support Email</Label>
                    <CopyInput
                        value={supportEmail}
                    />
                </SettingSection>

                {
                    mediaKit ?
                        <SettingSection>
                            <Label>Media Kit</Label>
                            <LinkInput
                                value={mediaKit}
                            />
                        </SettingSection>
                        :
                        null
                }

                <SettingSection>
                    <Label>Want to cancel your partnership?</Label>
                    <AntButton
                        danger
                        onClick={() => this.cancelPartnership()}
                    >
                        Cancel Partnership
                    </AntButton>
                </SettingSection>
            </Card>

        )
    }

    async updatePartnership(key, value) {
        const { id } = this.props.match.params;
        await this.props.updatePartnership(id, { [key]: value });
        message.success(`Updated ${_.startCase(key)} to ${value}`)
    }

    renderPartnershipSettings(partnerData) {

        const {
            isPartnerBrand
        } = partnerData;
        const {
            partnership
        } = this.props.partnership
        const { shopifyShippingZones } = this.props.shopify;

        return (
            <Card style={{ marginTop: 10 }}>


                <SettingSection>
                    <Tooltip
                        title="Setting a preferred shipping will force all orders created through ALLY to ship through the preferred shipping method."
                    >
                        <Label>Set a preferred shipping method (optional)</Label>
                        <Dropdown
                            data={shopifyShippingZones}
                            placeholder='Override Shipping Method'
                            value={partnership.partnership.shippingLabel}
                            onChange={(value) => this.updatePartnership('shippingLabel', value)}
                        />
                    </Tooltip>
                </SettingSection>

                    <SettingSection>
                        <Label>Should we automatically update product changes from the brand to the sellers shop (title, body, images)?</Label>
                        <Switch
                            checked={partnership.partnership.shouldSyncProduct}
                            onChange={(checked) => this.updatePartnership('shouldSyncProduct', checked)}
                        />
                    </SettingSection>
                    {
                        isPartnerBrand ?
                            <SettingSection>
                                <Label>Should we share customer data?</Label>
                                <Switch
                                    checked={partnership.partnership.shouldShareCustomerData}
                                    onChange={(checked) => this.updatePartnership('shouldShareCustomerData', checked)}
                                />
                            </SettingSection>
                            : null
                    }

            </Card>

        )
    }

    render() {
        const {
                    partnershipLoading,
                    partnership
                } = this.props.partnership
        const { id} = this.props.match.params;

        console.log(
                {
                    data: this.props.partnership
            }
        )
        if (partnershipLoading) {
            return <Container><LoadingSpinner /></Container>
        }

        if (!partnership) {
            return <Container><LoadingSpinner /></Container>
        }
        const partnerData = this.getPartnerData();
        const {
                    name,
                    brandDescription
                } = partnerData;
        return (
                <Container>
                    <PageHeader
                        onBack={() => this.props.history.goBack()}
                        title={name}
                        extra={[
                            <Button
                                onClick={() => this.props.history.push(`/partnership/${id}/products`)}
                            >
                                Manage Products
                        </Button>
                        ]}
                        footer={
                            <Tabs defaultActiveKey='1'>
                                <Tabs.TabPane tab='Details' key='1'>
                                    {this.renderPartnershipDetails(partnerData)}
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='Settings' key='2'>
                                    {this.renderPartnershipSettings(partnerData)}
                                </Tabs.TabPane>
                            </Tabs>
                        }
                    >
                        {brandDescription}
                    </PageHeader>

                </Container>
        );
    }
}

export default connectScreen(withRouter(Partnership));