import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Modal } from 'antd';
import LoadingSpinner from './LoadingSpinner';

export default ({
    data,
    userId,
    loading,
    //column props
    //accept button props
    onAcceptClick,
    acceptTitle,
    acceptDesc,
    //decline btn props
    onDeclineClick,
    declineTitle,
    declineDesc,
    //loading icon props
    showSyncing = false,
    //link button props
    partnerLink,
    partnerLinkText
}) => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    let columns = [
        {
            title: 'Fee',
            dataIndex: 'fee',
            render: (text, record) => <p>{record.fee}%</p>,

        },
        {
            title: 'Brand',
            dataIndex: 'Brand',
            render: (text, record) => <p>{record.Brand.company}</p>,
        },
        {
            title: 'Seller',
            dataIndex: 'Seller',
            render: (text, record) => <p>{record.Seller.company}</p>,
        },
        {
            title: 'Type',
            dataIndex: 'buyerId',
            render: (text, record) => <p>{record.brandId === userId ? 'Your products are being sold' : 'You are selling their products'}</p>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {
                        //synced 
                        record.status === 'Synced' ?
                            <Button
                                style={{ marginRight: 16 }}
                                type='primary'

                            >
                                <Link to={() => partnerLink(record)}>
                                    {partnerLinkText(record)}
                                </Link>
                            </Button>
                            : null
                    }
                    {
                        //pending
                        record.status === 'Pending' ?
                            <Button
                                style={{ marginRight: 16 }}
                                type='primary'
                                onClick={() => {
                                    Modal.confirm({
                                        title: acceptTitle,
                                        content: acceptDesc(record),
                                        onOk: () => onAcceptClick(record),
                                        okText: acceptTitle,
                                        onCancel: () => console.log('closed')
                                    })
                                }}
                            >
                                {acceptTitle}
                            </Button>
                            : null
                    }
                    {
                        //pending
                        record.status === 'Pending' ?
                            <Button
                                style={{ marginRight: 16 }}
                                type='danger'
                                onClick={() => {
                                    Modal.confirm({
                                        title: declineTitle,
                                        content: declineDesc(record),
                                        okButtonProps: { type: 'danger' },
                                        onOk: () => onDeclineClick(record),
                                        okText: declineTitle,
                                        onCancel: () => console.log('closed')
                                    })
                                }}
                            >
                                {declineTitle}
                            </Button>
                            : null
                    }
                    {
                        //syncing
                        record.status === 'Syncing' ?
                            <LoadingSpinner />
                            : null
                    }
                    {
                            record.status === 'Cancelled' ?
                            'Cancelled'
                            : null
                        }
                        {
                            record.status === 'Invited' ?
                            'Invited'
                            : null
                        }
                </span>
            ),
        },
    ]
    if (isMobile) {
        columns = [
            {
                title: 'Brand',
                dataIndex: 'Brand',
                render: (text, record) => <p>{record.Brand.company}</p>,
            },
            {
                title: 'Seller',
                dataIndex: 'Seller',
                render: (text, record) => <p>{record.Seller.company}</p>,
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {
                            record.status === 'Synced' ?
                                <Button
                                    style={{ marginRight: 16 }}
                                    type='primary'
    
                                >
                                    <Link to={() => partnerLink(record)}>
                                        {partnerLinkText(record)}
                                    </Link>
                                </Button>
                                : null
                        }
                        {
                            record.status === 'Pending' ?
                                <Button
                                    style={{ marginRight: 16 }}
                                    type='primary'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: acceptTitle,
                                            content: acceptDesc(record),
                                            onOk: () => onAcceptClick(record),
                                            okText: acceptTitle,
                                            onCancel: () => console.log('closed')
                                        })
                                    }}
                                >
                                    {acceptTitle}
                                </Button>
                                : null
                        }
                        {
                            record.status === 'Pending' ?
                                <Button
                                    style={{ marginRight: 16 }}
                                    type='danger'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: declineTitle,
                                            content: declineDesc(record),
                                            okButtonProps: { type: 'danger' },
                                            onOk: () => onDeclineClick(record),
                                            okText: declineTitle,
                                            onCancel: () => console.log('closed')
                                        })
                                    }}
                                >
                                    {declineTitle}
                                </Button>
                                : null
                        }
                        {
                            record.status === 'Syncing' ?
                                <LoadingSpinner />
                                : null
                        }
                        {
                            record.status === 'Cancelled' ?
                            'Cancelled'
                            : null
                        }
                        {
                            record.status === 'Invited' ?
                            'Invited'
                            : null
                        }
                    </span>
                ),
            },
        ]
    }
    return (
        <Table
            loading={loading}
            dataSource={data}
            rowKey='id'
            columns={columns}
        />
    )
}