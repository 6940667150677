import axios from 'axios';
import {
    PUBLIC_LINK_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_LINK_LOADING,
    GET_LINK
} from '../types';
import { message } from 'antd';

export default (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_LINK_LOADING,
                linkLoading: true
            })
            const { token } = getState().UserReducer;
            const linkResponse = await axios.get(`${PUBLIC_LINK_API}/${id}`, API_HEADER(token))
            dispatch({
                type: GET_LINK,
                linkLoading: false,
                link: linkResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_LINK_LOADING,
                linkLoading: false
            })

        }
    }
}