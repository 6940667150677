import React from 'react';
import styled from 'styled-components';
import {
    Select
} from 'antd';
const { Option } = Select;

const Container = styled(Select)`
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    font-size: 16px;
    border: 1px #E1E6ED solid;
    border-radius: 5px;
    transition: all 0.4s ease;
`

export default (props) => (
    <Container {...props}>
        {props.data ?
            props.data.map(option =>
            <Option value={option}>{option}</Option>
        )
            :
            null
        }
    </Container>
)