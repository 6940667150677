import {
    GET_PARTNERSHIPS_LOADING,
    GET_PARTNERSHIPS,
    ACCEPT_PARTNERSHIP_LOADING,
    UPDATE_PARTNERSHIP_LOADING,
    CANCEL_PARTNERSHIP_LOADING,
    CREATE_PARTNERSHIP_LOADING,
    GET_PARTNERSHIP_PRODUCTS_LOADING,
    GET_PARTNERSHIP_PRODUCTS,
    CREATE_PARTNERSHIP_PRODUCT_LOADING,
    DELETE_PARTNERSHIP_PRODUCT_LOADING,
    GET_PARTNERSHIP,
    GET_PARTNERSHIP_LOADING,
    UPDATE_PARTNERSHIP,
    SYNC_PARTNERSHIP_PRODUCTS_LOADING
} from '../actions/types';

const defaultState = {
    partnershipsLoading: false,
    partnerships: [],
    partnershipLoading: false,
    partnership: null,
    acceptingPartnership: false,
    updatingPartnership: false,
    cancellingPartnership: false,
    creatingPartnership: false,
    partnershipProductsLoading: false,
    partnershipProducts: [],
    creatingPartnershipProduct: false,
    deletingPartnershipProduct: false,
    syncingPartnershipProducts: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_PARTNERSHIPS_LOADING:
            return {
                ...state,
                partnershipsLoading: action.partnershipsLoading,
            }
        case GET_PARTNERSHIPS:
            return {
                ...state,
                partnershipsLoading: action.partnershipsLoading,
                partnerships: action.partnerships
            }
        case GET_PARTNERSHIP_LOADING:
            return {
                ...state,
                partnershipLoading: action.partnershipLoading,
            }
        case GET_PARTNERSHIP:
            return {
                ...state,
                partnershipLoading: action.partnershipLoading,
                partnership: action.partnership
            }
        case GET_PARTNERSHIP_PRODUCTS_LOADING:
            return {
                ...state,
                partnershipProductsLoading: action.partnershipProductsLoading,
            }
        case GET_PARTNERSHIP_PRODUCTS:
            return {
                ...state,
                partnershipProductsLoading: action.partnershipProductsLoading,
                partnershipProducts: action.partnershipProducts
            }
        case ACCEPT_PARTNERSHIP_LOADING:
            return {
                ...state,
                acceptingPartnership: action.acceptingPartnership
            }
        case UPDATE_PARTNERSHIP:
            return {
                ...state,
                updatingPartnership: action.updatingPartnership,
                partnership: action.partnership
            }
        case UPDATE_PARTNERSHIP_LOADING:
            return {
                ...state,
                updatingPartnership: action.updatingPartnership
            }
        case CANCEL_PARTNERSHIP_LOADING:
            return {
                ...state,
                cancellingPartnership: action.cancellingPartnership
            }
        case CREATE_PARTNERSHIP_LOADING:
            return {
                ...state,
                creatingPartnership: action.creatingPartnership
            }
        case CREATE_PARTNERSHIP_PRODUCT_LOADING:
            return {
                ...state,
                creatingPartnershipProduct: action.creatingPartnershipProduct
            }
        case DELETE_PARTNERSHIP_PRODUCT_LOADING:
            return {
                ...state,
                deletingPartnershipProduct: action.deletingPartnershipProduct
            }
        case SYNC_PARTNERSHIP_PRODUCTS_LOADING:
            return {
                ...state,
                syncingPartnershipProducts: action.syncingPartnershipProducts
            }
        default:
            return state;
    }
}