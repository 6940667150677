import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { message } from 'antd';
import connectScreen from '../../redux/connectScreen';
import OnboardingContainer from '../../components/OnboardingContainer';
import OnboardingSideBar from '../../components/OnboardingSideBar';
import OnboardingContent from '../../components/OnboardingContent';
import Button from '../../components/Button';
import OnboardingButtonContainer from '../../components/OnboardingButtonContainer';
import CloseButton from '../../components/CloseButton';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Switch from '../../components/Switch';

const SettingSection = styled.div`
    margin-top: 10px;
`
class Profile extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            isSeller: dbUser && dbUser.isSeller ? dbUser.isSeller : false,
            payoutEmail: dbUser && dbUser.payoutEmail ? dbUser.payoutEmail : '',
        }
    }

    async saveUser() {
        const { payoutEmail, isSeller } = this.state;
        await this.props.updateUser({
            onboardingStep3Finished: true,
            isSeller,
            hasOnboarded: !isSeller,
            payoutEmail
        })

    }


    async onNext() {
        //Savee
        const { payoutEmail, isSeller } = this.state;
        if (
            payoutEmail !== ''
        ) {
            await this.saveUser();
            if (isSeller) {
                this.props.history.push('/onboarding/paypalSeller');
            } else {
                this.props.history.push('/partners');

            }
        } else {
            message.error('Please enter your Paypal Email')
        }
    }
    render() {
        const {
            isSeller,
            payoutEmail
        } = this.state;

        return (
            <OnboardingContainer>

                <OnboardingSideBar
                    title='Create your account'
                    subtitle='Create your account in a few steps and start selling your products'
                />
                <OnboardingContent
                    title='Connect Your Paypal'
                    subtitle='Receive payouts from your retail partners, and pay suppliers via paypal'
                    percent={75}

                >
                    <SettingSection>
                        <Label>Paypal Payout Email</Label>
                        <Input
                            placeholder='Paypal Email'
                            type='payoutEmail'
                            value={payoutEmail}
                            onChange={e => this.setState({ payoutEmail: e.target.value })}
                        />
                    </SettingSection>
                    <SettingSection
                        style={{ marginBottom: 100 }}
                    >

                        <Label>Would you like to also sell other products in the Ally Network on my store?</Label>
                        <Switch
                            checked={isSeller}
                            onChange={checked => this.setState({ isSeller: checked })}
                        />
                    </SettingSection>
                    <OnboardingButtonContainer>
                        <Button
                            onClick={() => this.onNext()}
                        >
                            Next
                        </Button>
                        <CloseButton
                            onClick={() => this.props.history.goBack()}
                        >
                            BACK
                        </CloseButton>
                    </OnboardingButtonContainer>

                </OnboardingContent>
            </OnboardingContainer>
        )
    }
}

export default withRouter(connectScreen(Profile));