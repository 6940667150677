import {
    CREATE_LINK_LOADING,
    UPDATE_LINK_LOADING,
    GET_LINK,
    GET_LINK_LOADING,
    GET_LINKS,
    GET_LINKS_LOADING,
    GET_USER_SHOP_URL,
    GET_USER_SHOP_URL_LOADING,
    CREATE_PARTNERSHIP_WITH_LINK_LOADING
} from '../actions/types';
const defaultState = {
    creatingLink: false,
    updatingLink: false,
    linkLoading: false,
    link: null,
    linksLoading: false,
    links: [],
    userShopURLLoading: false,
    linkShopURL: null,
    creatingPartnershipWithLink: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_LINK_LOADING:
            return {
                ...state,
                creatingLink: action.creatingLink
            }
        case UPDATE_LINK_LOADING:
            return {
                ...state,
                updatingLink: action.updatingLink
            }
        case GET_LINK:
            return {
                ...state,
                linkLoading: action.linkLoading,
                link: action.link
            }
        case GET_LINK_LOADING:
            return {
                ...state,
                linkLoading: action.linkLoading,
            }
        case GET_LINKS:
            return {
                ...state,
                linksLoading: action.linksLoading,
                links: action.links
            }
        case GET_LINKS_LOADING:
            return {
                ...state,
                linksLoading: action.linksLoading,
            }
        case GET_USER_SHOP_URL:
            return {
                ...state,
                userShopURLLoading: action.userShopURLLoading,
                linkShopURL: action.linkShopURL
            }
        case GET_USER_SHOP_URL_LOADING:
            return {
                ...state,
                userShopURLLoading: action.userShopURLLoading,
            }
        case CREATE_PARTNERSHIP_WITH_LINK_LOADING:
            return {
                ...state,
                creatingPartnershipWithLink: action.creatingPartnershipWithLink
            }
        default:
            return state;
    }
}