import axios from 'axios';
import {
    SHOPIFY_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_SHOPIFY_SHIPPING_ZONES_LOADING,
    GET_SHOPIFY_SHIPPING_ZONES
} from '../types';
import { message } from 'antd';

export default () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_SHOPIFY_SHIPPING_ZONES_LOADING,
                shopifyShippingZonesLoading: true
            })
            const { token } = getState().UserReducer;
            const shippingZonesResponse = await axios.get(`${SHOPIFY_API}/shippingZones`, API_HEADER(token))
            console.log({ shippingZonesResponse})
            dispatch({
                type: GET_SHOPIFY_SHIPPING_ZONES,
                shopifyShippingZonesLoading: false,
                shopifyShippingZones: shippingZonesResponse.data
            })
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_SHOPIFY_SHIPPING_ZONES_LOADING,
                shopifyShippingZonesLoading: false
            })

        }
    }
}