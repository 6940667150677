import axios from 'axios';
import {
    PUBLIC_LINK_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_USER_SHOP_URL_LOADING,
    GET_USER_SHOP_URL
} from '../types';
import { message } from 'antd';

export default (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_USER_SHOP_URL_LOADING,
                userShopURLLoading: true
            })
            const { token } = getState().UserReducer;
            const linkShopURLResponse = await axios.post(`${PUBLIC_LINK_API}/shop`, data , API_HEADER(token))
            dispatch({
                type: GET_USER_SHOP_URL,
                userShopURLLoading: false,
                linkShopURL: linkShopURLResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_USER_SHOP_URL_LOADING,
                userShopURLLoading: false
            })

        }
    }
}