import React, { Component } from 'react';
import { List, Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import connectScreen from '../../redux/connectScreen';
import Container from '../../components/Container';
import Input from '../../components/Input';
import Submenu from '../../components/Submenu';

const CardMeta = styled(Card.Meta)`
    .ant-card-meta-detail {
        height: 120px;
    }
`

class Discover extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: this.getDefaultSelected(),
            shouldShowBrandAndSeller: dbUser.isBrand && dbUser.isSeller,
            search: '',
        }
    }


    componentDidMount() {
        this.searchUsers();

    }

    getDefaultSelected() {
        const { dbUser } = this.props.user;
        if (!dbUser.isBrand && dbUser.isSeller) {
            //ONLY Seller
            return 'Brands';
        } else {
            // isBrand OR is not both OR is both
            return 'Retailers'
        }
    }

    searchUsers() {
        const { selected, search } = this.state;
        const query = `search=${search}&selected=${selected}`;
        this.props.getUsers(query);

    }

    setType(selected) {
        this.setState({
            selected
        }, () => this.searchUsers())
    }

    onSearch(search) {
        this.setState({
            search
        }, () => this.searchUsers())
    }
    render() {
        const { shouldShowBrandAndSeller } = this.state;
        const { users } = this.props.user;
        return (
            <Container>
                {
                    shouldShowBrandAndSeller ? <Submenu
                        menuItems={['Retailers', 'Brands']}
                        onSelect={(selected) => this.setType(selected)}
                        selected={this.state.selected}
                    />
                        : null
                }
                <Input
                    placeholder='Search by company name or description...'
                    onChange={(e) => this.onSearch(e.target.value)}
                />
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 3,
                    }}
                    style={{ marginTop: 20 }}
                    dataSource={users}
                    renderItem={item => (
                        <List.Item>
                            <Link
                                to={`/discover/${item.id}`}

                            >
                                <Card
                                    style={{ 'height': 300 }}
                                    hoverable
                                    cover={
                                        <div style={{ border: '1px solid #DFE3E7', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                            <img
                                                alt={item.company}
                                                src={item.logo || require('../../images/logo-grey.png')}
                                                style={{ height: 100, maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        </div>
                                    }
                                >
                                    <CardMeta
                                        title={item.company}
                                        description={item.brandDescription}
                                        style={{ overflow: 'hidden' }}
                                    />
                                </Card>
                            </Link>

                        </List.Item>
                    )}
                />
            </Container>
        )
    }
}

export default connectScreen(Discover)