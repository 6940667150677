export const API = process.env.REACT_APP_API;
export const PUBLIC = process.env.REACT_APP_PUBLIC_API;
export const API_HEADER = (token) => { return {headers: {"Authorization" : `Bearer ${token}`}} }
export const SHOPIFY_INSTALL_URL = process.env.REACT_APP_SHOPIFY_INSTALL_URL;
export const USERS_API = `${API}/users`;
export const SHOPIFY_API = `${API}/shopify`
export const PRODUCTS_API = `${API}/products`;
export const PARTNERSHIP_API = `${API}/partnerships`;
export const PAYOUT_API = `${API}/payouts`;
export const ORDERS_API = `${API}/orders`;
export const REPORTS_API = `${API}/reports`;
export const LINK_API = `${API}/links`;
export const PUBLIC_LINK_API = `${PUBLIC}/links`;
