import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_DISCOVER_USER_LOADING,
    GET_DISCOVER_USER
} from '../types';
import { message } from 'antd';

export default (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_DISCOVER_USER_LOADING,
                loadingDiscoverUser: true
            });
            const { token } = getState().UserReducer;
            const getDiscoverUserResponse = await axios.get(`${USERS_API}/discover/${id}`, API_HEADER(token));
            dispatch({
                type: GET_DISCOVER_USER,
                loadingDiscoverUser: false,
                discoverUser: getDiscoverUserResponse.data
            });
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_DISCOVER_USER_LOADING,
                loadingDiscoverUser: false
            })
        }
    }
}