import React from 'react';
import styled from 'styled-components';
import OnboardingProgressBar from './OnboardingProgressBar';

const Container = styled.div`
    width: 80%;
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    padding: 10px 60px 40px 60px;
    border: 1px solid #EEF4F7;
    margin-left: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
        margin-left: 0px;
    }
`

const Title = styled.h2`
    color: black;
    font-size: 38px;
    margin-bottom: 10px;
`;
const Subtitle = styled.p`
    color: #6b6b6b;
    font-size: 16px;

`;

export default (props) => (
    <Container>
        <OnboardingProgressBar
            percent={props.percent}
        />
        <Title>
            {props.title}
        </Title>
        <Subtitle>
            {props.subtitle}
        </Subtitle>
        {props.children}
    </Container>
)