import styled from 'styled-components';

export default styled.div`
    padding: 40px;
    min-height: calc(100vh - 80px);
    weight: 100vw;
    display: flex;
    overflow-y: scroll;
    @media (max-width: 768px) {
        padding: 0px;

    }
`