import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    ADD_CARD_LOADING,
} from '../types';
import { message } from 'antd';

export default (data) => {
    return async (dispatch, getState) => {
        try {
            const { token } = getState().UserReducer;
            dispatch({
                type: ADD_CARD_LOADING,
                loadingAddCard: true
            });
            await axios.post(`${USERS_API}/stripe/card`, data, API_HEADER(token));
            dispatch({
                type: ADD_CARD_LOADING,
                loadingAddCard: false
            });
            window.location.reload()
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: ADD_CARD_LOADING,
                loadingAddCard: false
            });
        }
    }
}