import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { Alert, message } from 'antd';
import connectScreen from '../../redux/connectScreen';
import OnboardingContainer from '../../components/OnboardingContainer';
import OnboardingSideBar from '../../components/OnboardingSideBar';
import OnboardingContent from '../../components/OnboardingContent';
import Button from '../../components/Button';
import OnboardingButtonContainer from '../../components/OnboardingButtonContainer';
import CloseButton from '../../components/CloseButton';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Title from '../../components/Title';

const SettingSection = styled.div`
    margin-top: 10px;
`
const TextLink = styled.a`
    width: 100%;
    text-align: center;
    margin-top: 10px;
`
class Profile extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: 'General',
            company: dbUser && dbUser.company ? dbUser.company : '',
            isBrand: dbUser && dbUser.isBrand ? dbUser.isBrand : false,
            isSeller: dbUser && dbUser.isSeller ? dbUser.isSeller : false,
            shop: dbUser && dbUser.shop ? dbUser.shop : null,
            code: dbUser && dbUser.code ? dbUser.code : '',
            payoutEmail: dbUser && dbUser.payoutEmail ? dbUser.payoutEmail : '',
            payoutClientId: dbUser && dbUser.payoutClientId ? dbUser.payoutClientId : '',
            payoutSecretId: dbUser && dbUser.payoutSecretId ? dbUser.payoutSecretId : '',
            logo: dbUser && dbUser.logo ? dbUser.logo : null,
            mediaFilesLink: dbUser && dbUser.mediaFilesLink ? dbUser.mediaFilesLink : '',
            supportEmail: dbUser && dbUser.supportEmail ? dbUser.supportEmail : '',
            brandDescription: dbUser && dbUser.brandDescription ? dbUser.brandDescription : '',
            shopifyModalVisible: false,
            shopifyShopUrl: '',
            shopifyAPIPassword: '',
            isLoading: false
        }
    }

    async saveUser() {
        await this.props.updateUser({
            onboardingStep2Finished: true
        })

    }

    async connectShopify() {
        const { shopifyShopUrl, shopifyAPIPassword } = this.state;
        await this.saveUser();
        const isAccessGranted = await this.checkScopes(shopifyShopUrl, shopifyAPIPassword);
        if (isAccessGranted) {
            await this.props.startShopifyIntegration(shopifyShopUrl, shopifyAPIPassword);
            await this.saveUser();
        }
    }

    async checkScopes(shop, accessToken) {
        const scopeInstructions = await this.props.checkScopes(shop, accessToken);
        if (scopeInstructions && scopeInstructions.length <= 0) {
            return true;
        } else {
            return false
        }
    }

    async onNext() {
        //Save
        const {
            shopifyShopUrl,
            shopifyAPIPassword,
            shop
        } = this.state;
        if (shop) {
            await this.props.history.push('/onboarding/paypal')
            this.setState({ isLoading: false });
        } else {
            if (
                shopifyShopUrl !== '' &&
                shopifyAPIPassword !== ''
            ) {
                this.setState({ isLoading: true });
                await this.connectShopify();
                await this.props.history.push('/onboarding/paypal')
                this.setState({ isLoading: false });
            } else {
                message.error('Please enter your shopify url and API password')
            }
        }

    }
    render() {
        const {
            shop,
            isLoading,
            shopifyShopUrl,
            shopifyAPIPassword,
        } = this.state;
        const { scopeInstructions } = this.props.shopify;

        return (
            <OnboardingContainer>

                <OnboardingSideBar
                    title='Create your account'
                    subtitle='Create your account in a few steps and start selling your products'
                />
                <OnboardingContent
                    title='Connect Your Shopify'
                    subtitle='Installing the Ally App'
                    percent={50}

                >
                    <iframe
                        src="https://www.loom.com/embed/dc7f42bb31cb4463a9e9a217d0d85f0d"
                        frameborder="0"
                        width='100%'
                        height='300px'
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        title='shopifyInstructionsVideo'
                    >
                    </iframe>
                    <TextLink
                        rel="noopener noreferrer"
                        target='_blank'
                        href='https://joinally.freshdesk.com/support/solutions/articles/64000111876-connecting-shopify-as-a-brand-'
                    >
                        or view written instructions here
                    </TextLink>
                    {
                        !shop ?
                            <>

                                <SettingSection>
                                    <Label>What is your Shopify Url (without https/https) ?</Label>
                                    <Input
                                        placeholder='What is your Shopify Url (without https/https) '
                                        value={shopifyShopUrl}
                                        onChange={(e) => this.setState({ shopifyShopUrl: e.target.value })}
                                    />
                                </SettingSection>
                                <SettingSection>

                                    <Input
                                        placeholder='Your Shopify API Password'
                                        value={shopifyAPIPassword}
                                        onChange={(e) => this.setState({ shopifyAPIPassword: e.target.value })}
                                    />
                                </SettingSection>

                                {
                                    scopeInstructions.length > 0 ?
                                        <Alert
                                            message="Error connecting, you need to enable more permissions:"
                                            type="error"
                                            description={
                                                scopeInstructions.map((scope, index) => <p>{index + 1}) {scope.scope} access missing. {scope.instruction}</p>)
                                            }
                                        />
                                        : null

                                }
                            </>
                            :
                            <SettingSection>
                                <Title>
                                    {shop.url} connected
                                </Title>
                            </SettingSection>

                    }
                    <OnboardingButtonContainer>
                        <Button
                            onClick={() => this.onNext()}
                            loading={isLoading}
                        >
                            Next
                        </Button>
                        <CloseButton
                            onClick={() => this.props.history.goBack()}
                        >
                            BACK
                        </CloseButton>
                    </OnboardingButtonContainer>

                </OnboardingContent>
            </OnboardingContainer>
        )
    }
}

export default withRouter(connectScreen(Profile));