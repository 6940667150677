import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const Button = styled.button`
    color: #656565;
    background-color: transparent;
    cursor: pointer;
    border: none;
    box-shadow: none);
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    outline: 0 !important;
    :focus {outline:0 !important;}

`

export default (props) => (
    <Button {...props}>
        {props.loading ?
            <Spinner data-test='loading-button' animation="border" />
            :
            props.children
        }
    </Button>
)