import React, { Component } from 'react';
import { Table } from 'antd';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Submenu from '../components/Submenu';

class Payouts extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: this.getDefaultSelected(),
            shouldShowReceivedAndSent: dbUser.isBrand && dbUser.isSeller,

        }
    }

    componentDidMount() {
        this.getPayouts()
    }
    getDefaultSelected() {
        const { dbUser } = this.props.user;
        if (!dbUser.isBrand && dbUser.isSeller) {
            //ONLY Seller
            return 'Sent';
        } else {
            // isBrand OR is not both OR is both
            return 'Received'
        }
    }

    getPayouts() {
        const { selected } = this.state;
        this.props.getPayouts(selected)
    }

    setType(selected) {
        this.setState({
            selected
        }, () => this.getPayouts())
    }


    getColumns() {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if (isMobile) {
            return [
                {
                    title: 'Payout Date',
                    dataIndex: 'createdAt',
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                },
            ]
        }
        return [
            {
                title: 'Ref #',
                dataIndex: 'payoutReferenceId',
            },
            {
                title: 'Payout Date',
                dataIndex: 'createdAt',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
            },
            {
                title: 'Payout Email',
                dataIndex: 'payoutEmail',
            },
        ]
    }
    render() {
        const { payouts, loadingPayouts } = this.props.payout;
        const { shouldShowReceivedAndSent } = this.state;
        return (
            <Container>
                {
                    shouldShowReceivedAndSent ? <Submenu
                        menuItems={['Received', 'Sent']}
                        onSelect={(selected) => this.setType(selected)}
                        selected={this.state.selected}
                    />
                        : null
                }
                <Table
                    loading={loadingPayouts}
                    dataSource={payouts}
                    columns={this.getColumns()}
                />

            </Container>
        )
    }
}

export default connectScreen(Payouts)